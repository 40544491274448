import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from "lodash"
import { useApp, useDesktop, useLang, useLanguage } from './common'
import { useIntl } from 'react-intl'
import Cookies from 'js-cookie'


/**
 * 23年2月24日测试用链接
 * 环境 cro
 * 有fitType的衣服
 *   msite: /product/-/0f39fa42-5009-4ef3-bbf2-58ac40641d56.html
 *   app: /sp/sizechart?productId=0f39fa42-5009-4ef3-bbf2-58ac40641d56&countryCode=BR&isApp=1
 * bra类型
 *   msite: /product/test-size-us-bra/e6f26b6c-b528-4479-ac3a-4a5faeaebe16.html
 *   app: /sp/sizechart?productId=e6f26b6c-b528-4479-ac3a-4a5faeaebe16&countryCode=BR&isApp=1
 * 套装类型
 *   msite:
 *   app:
 * 鞋子类型
 *  /product/-/422ecdf2-548d-42e8-8752-9abe601154b5.html // 最新的用这个测
 * swimwear
 *   /product/-/K1385.html // 类型是衣服 没有measurements
 * lingerie
 *  product/xy011303/43213654-2850-47a9-a5c8-db5d4e4ded50.html // 类型是衣服 没有measurements
 */

// 获取当前产品的相关数据
const useProductDetail = (appProduct, products, selectedProduct) => {
    const selectedProductNew = useMemo(() => {
        return selectedProduct ? selectedProduct: appProduct ? appProduct?.data?.[0] : products?.[0]
    }, [products, appProduct, selectedProduct])

    // 所有产品
    const productsNew = useMemo(() => {
        return appProduct?.data || products || []
    }, [appProduct, products])

    // 所有size不重复的子产品
    const productVariants = useMemo(() => {
        let list = []
        productsNew?.forEach(ps => {
            list.push(...ps.variants);
        })
        return _.uniqBy(list, 'size')
    }, [productsNew])

    // 所有不重复的size
    const productSizes = useMemo(() => {
        return productVariants?.map(variant => {
            return { size: variant?.size, size2: variant?.size2 }
        })
    }, [productVariants])

    // sizechart数据
    const measurements = useMemo(() => {
        return selectedProductNew?.sizeChart2?.measurements || []
    }, [selectedProductNew])

    const setSelectList = useMemo(() => {
        let list = []
        measurements?.forEach(m => {
            if (m?.parts && list?.indexOf(m?.parts) == -1) {
                list.push(m?.parts)
            }
        })
        return list
    }, [measurements])

    // 产品类型
    const productType = useMemo(() => {
        return selectedProductNew?.sizeChart2?.id == 1 ? 'shoes' :
            selectedProductNew?.sizeChart2?.id == 2 ? 'clothing' :
                selectedProductNew?.sizeChart2?.id == 3 ? 'bra' : undefined
    }, [selectedProductNew])

    // 有没有sizeChart数据
    const hasSizeChartData = useMemo(() => {
        return productsNew?.find(p => p?.sizeChart2) ? true : false
    }, [productsNew])

    const isPlusSize = useMemo(() => {
        return productsNew?.[0]?.plus
    }, [productsNew])

    return {
        productsNew,
        productVariants,
        productSizes,
        productType,
        hasSizeChartData,
        measurements,
        setSelectList,
        isPlusSize
    }
}

const formateSize = (size, productType) => {
    // 针对bra尺寸为 72B(32B)这种格式的 排除掉鞋子的这种情况
    if (size?.indexOf('(') != -1 && productType == 'bra') {
        let sizeProduct = size.split('(')[0]
        let sizeUs = size.split("(")[1].split(")")[0]
        // console.log('formateSize: ', size, sizeProduct, sizeUs)
        return { size: sizeProduct, sizeUs: sizeUs }
    } else {
        return { size: size, sizeUs: size }
    }
}

// 普通衣服 套装用这个
const useMeasurements = (measurements, productType, intl) => {

    const dataFilter = useMemo(() => {
        if (!measurements || measurements?.length == 0) return []

        let measurementsClone = _.cloneDeep(measurements)
        if (measurementsClone?.length <= 2) {
            return [{ part: 'all', data: measurementsClone }]
        } else {
            let list = []
            measurementsClone?.forEach(m => {
                let targetObj = list.find(l => l.part == m.parts)
                if(targetObj){
                    targetObj.data.push(m)
                } else {
                    list.push({
                        part: m?.parts,
                        data: [m]
                    })
                }
            })
            return list
        }
    }, [measurements])

    const tableData = useMemo(() => {
        let dataFinal = []
        dataFilter?.forEach(d => {
            let measurements = d?.data || []
            let inchData = measurements?.find(m => m?.unit == 'inch');
            let cmData = measurements?.find(m => m?.unit == 'cm');
            let tableHead = { inch: [], cm: [] }, tableBody = { inch: [], cm: [] }
            inchData?.tableProperties?.forEach((inchT) => {
                if (Array.isArray(inchT) && inchT?.length > 0) {
                    inchT?.forEach((inc) => {
                        if (inc?.value && !tableHead.inch.find(inc1 => inc1?.title == inc?.title)) {
                            tableHead.inch.push({ title: inc?.title })
                        }
                        if (inc?.name == 'size') {
                            inc.size = formateSize(inc?.value, productType).size
                            inc.sizeUs = formateSize(inc?.value, productType).sizeUs
                        }
                    })
                    tableBody.inch.push(inchT)
                }
            })
            cmData?.tableProperties?.forEach((cmT) => {
                if (Array.isArray(cmT) && cmT?.length > 0) {
                    cmT?.forEach((cmc) => {
                        if (cmc?.value && !tableHead.cm.find(c => c?.title == cmc?.title)) {
                            tableHead.cm.push({ title: cmc?.title })
                        }
                        if (cmc?.name == 'size') {
                            cmc.size = formateSize(cmc?.value, productType).size
                            cmc.sizeUs = formateSize(cmc?.value, productType).sizeUs
                        }
                    })
                    // console.log("cmT: ", cmT)
                    tableBody.cm.push(cmT)
                }
            })
            dataFinal.push({
                part: d?.part,
                tableHead,
                tableBody
            })
        })
        return [...dataFinal]
    }, [productType, dataFilter])

    return {
        tableData
    }
}

// 鞋子用这个 暂时不用了
const useShoesTable = (productType, countrySizeList, productVariants, defaultCountry, useCountryLang, formateSize) => {

    const shoesData = useMemo(() => {
        if(productType != 'shoes')return []
        let dataList = []
        productVariants?.forEach(p => {
            let targetItem = countrySizeList?.find(cs => {
                return cs?.find(cs1 => {
                    // 如果产品的size2跟产品的size2相同 或者 产品详情页显示美国标准
                    return cs1?.country == defaultCountry && cs1?.size == formateSize(p?.size2, productType)?.sizeUs ||
                           cs1?.country == useCountryLang && cs1?.size == formateSize(p?.size2, productType)?.sizeUs
                })
            });
            if(targetItem){
                dataList.push(targetItem)
            }
        })
        if(dataList?.length > 0){
            let shoesTableHead = dataList?.[0].map(st => {
                return {name:st.country, title:st.country}
            })
            const index = shoesTableHead.findIndex(({ name }) => name === defaultCountry);
            if (index >= 0) {
                shoesTableHead.splice(0, 0, shoesTableHead.splice(index, 1)[0]);
            }
            let shoesTableBody = dataList?.map(st => {
                let tableLine = st.map(stm => {
                    return stm?.size
                })
                return tableLine
            })
            if (index >= 0) {
                shoesTableBody.forEach(sb => {
                    sb.splice(0, 0, sb.splice(index, 1)[0]);
                })
            }
            return [{
                part: 'all',
                tableHead: shoesTableHead,
                tableBody: {"cm":shoesTableBody,"inch":shoesTableBody}
            }]
        }
    }, [productVariants, countrySizeList, defaultCountry, useCountryLang, productType])
    

    return {
        shoesData
    }
}

/**
 * @param {object} productSizeData //
 * @param {string} taglia // 衣服的类型
 * @param {object} appProduct // app的时候传
 * @param {object} products // 当前sku对应的产品列表
 * @param {string} countryCodeCache // 缓存记录的国家
 * @param {object} selectedProduct // 选中的产品
 */

export const useSizeChartNew = (productSizeData, taglia, appProduct, products, countryCodeCache, selectedProduct) => {
    const isApp = useApp()
    const isDesktop = useDesktop()
    const useCountryLang = useLang()
    const textLang = useLanguage()
    const intl = useIntl()
    const {
        productsNew,
        productVariants,
        productSizes,
        productType,
        hasSizeChartData,
        measurements,
        setSelectList,
        isPlusSize
    } = useProductDetail(isApp ? appProduct : null, products, selectedProduct)
    const deviceType = useMemo(() => isDesktop ? 'pc' : 'msite', [isDesktop])

    const { tableData } = useMeasurements(measurements, productType, intl)

    const sizeChart2Data = productsNew?.[0]?.sizeChart2
    // 可切换的国家列表
    const countryList = sizeChart2Data?.supportCountries
    // 所有的尺寸信息
    const countrySizeList = sizeChart2Data?.countryAndSizesList

    // console.log("useProductDetail --> ",{productsNew,
    //     productVariants,
    //     productSizes,
    //     productType,
    //     hasSizeChartData,
    //     measurements,
    //     setSelectList})

    const defaultCountry = useMemo(() => {
        // 根据ip获取默认显示的国家
        const countryListHasIpCountry = countrySizeList?.find(scl => scl.value == useCountryLang)?.value
        return appProduct?.countryCode || countryCodeCache || countryListHasIpCountry || sizeChart2Data?.defaultCountry
    }, [appProduct, sizeChart2Data, countrySizeList, useCountryLang])

    // const { shoesData } = useShoesTable(
    //     productType,
    //     countrySizeList,
    //     productVariants,
    //     defaultCountry,
    //     useCountryLang,
    //     formateSize,
    //     )

    // product measurements里面的图片
    const imgSrc = useMemo(() => {
        return productSizeData?.image?.[deviceType]?.[taglia]
    }, [productSizeData, deviceType, taglia])

    const getModelStature = useCallback((model, pattern) => {
        if (!model) {
            return;
        }
        let arr = [];
        model?.forEach(item => {
            let obj = {};
            if (item?.height && item?.weight) {
                let value;
                if (pattern == "cm") {
                    value = item.height + "cm/" + item.weight + "kg";
                } else {
                    value = item.height + "inch/" + item.weight + "kg";
                }

                obj["height"] = {
                    title: intl.formatMessage({ id: "height_waist" }),
                    content: value
                }
            }
            if (item?.bust && item?.waist && item?.hip) {
                let value = pattern == "cm" ? "(cm)" : "(inch)";
                obj["measure"] = {
                    title: intl.formatMessage({ id: "measure" }),
                    content: `${item.bust}/${item.waist}/${item.hip}` + value
                }
            }

            if (item?.wear) {
                obj["mode_wear"] = {
                    title: intl.formatMessage({ id: "model_wear" }),
                    content: item.wear
                };
            }

            let obj2 = {};
            obj2["name"] = item?.name;
            obj2["detail"] = obj;

            arr.push(obj2);
        });
        return arr;
    }, [])

    const productsTrans = useMemo(() => {
        let fitStr = {
            'fitType': productsNew?.[0]?.['fitType'],
            'stretch': productsNew?.[0]?.['stretch']
        }
        let modalDataFinal = null
        let modalData = productsNew?.[0]?.modelStature
        if (modalData?.modelListOnCm?.length > 0 && modalData?.modelListOnInch?.length > 0) {
            modalDataFinal = {
                cm: getModelStature(modalData?.modelListOnCm, 'cm'),
                inch: getModelStature(modalData?.modelListOnCm, 'inch'),
            }
        }
        return {
            fitStr,
            modalDataFinal
        }
    }, [productsNew])

    const productSizeDataTrans = useMemo(() => {
        let bodyMeasurementList = [], braMeasurementList = []
        if(productSizeData?.bodyMeasurement){
            let data = isPlusSize ? productSizeData?.bodyMeasurement?.large: productSizeData?.bodyMeasurement?.normal
            data?.forEach(bs => {
                bodyMeasurementList.push({
                    size: bs?.size,
                    data:{
                        cm: bs?.cm,
                        inch: bs?.inch
                    }
                })
            })
        }
        if(productSizeData?.braMeasurement){
            let data = productSizeData?.braMeasurement?.normal
            data?.forEach(brs => {
                braMeasurementList.push({
                    size: brs?.size,
                    data:{
                        cm: brs?.cm,
                        inch: brs?.inch
                    }
                })
            })
        }
        return {
            productMeasurementData: productSizeData?.productMeasurement,
            bodyMeasurementData: bodyMeasurementList,
            braMeasurementData: braMeasurementList,
            shoesMeasurementData: productSizeData?.shoesMeasurement,
            fitTypeList: productSizeData?.fitTypeList?.[textLang || 'en'] || [],
            stretchList: productSizeData?.stretchList?.[textLang || 'en'] || []
        }
    }, [productSizeData, isPlusSize])

    const sizeCountryList = useMemo(() => {
        let list = []
        countryList?.forEach(cl => {
            list.push({
                label: cl,
                value: cl
            })
        })
        return list
    }, [countryList])

    const showCountryList = useMemo(() => {
        let flag = countrySizeList?.find(cs => {
            return cs?.find(cs1 => {
                return productSizes.find(ps => ps.size == cs1.size)
            })
        })
        return !!flag
    }, [productSizes, countrySizeList])

    return {
        productType,
        hasSizeChartData,
        defaultCountry,
        imgSrc,
        sizeCountryList,
        ...productSizeDataTrans,
        ...productsTrans,
        tableData,
        setSelectList,
        countrySizeList,
        formateSize,
        // shoesData
        showCountryList
    }
}