import React, { useEffect, useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import Header from '../fragments/header'
import { useScroll } from '../../../hooks/scroll'
import { TOOGLE_UI_DIRECTION, TOGGLE_UI_LOTTERY } from '../../../../store/actions/ui'
import { setEditingProduct, setSizeChart, setShowSizeChart } from '../../../../store/actions/global'
import { useDispatch, useSelector } from 'react-redux'
import ProductEditor from '../../pages/listing/components/cart/product-editor'
import AsideTools from '../fragments/aside-tools'
import IndexAlert from '../fragments/fixed-icon'
import Notifications from '../fragments/notifications'
import Raffle from '../fragments/raffle'
import { useQuery } from '../../../hooks/common'
// import SizeChart from '../fragments/size-chart'
import { useLocation } from 'react-router-dom'
import SizeChartWrapped from '../fragments/size-chart2';
import OPLoading from "../../../../client/components/loading/op-loading";

export default props => {
    useStyles(classes)

    const dispatch = useDispatch()
    const direction = useScroll()
    const global = useSelector(state => state.global)
    const ui = useSelector(state => state.ui)
    const { editingProduct, showSizeChart } = global

    const location = useLocation();
    const query = useQuery()


    useEffect(() => {
        dispatch({
            type: TOGGLE_UI_LOTTERY,
            payload: !!query?.show_lottery
        })
    }, [])

    useEffect(() => {
        dispatch({
            type: TOOGLE_UI_DIRECTION,
            payload: direction
        })
    }, [direction])


    return <div>
        <Header />
        <div className={classes.LayOutMain}>
            {props.children}
        </div>

        <AsideTools />

        {
            location.pathname !== '/landing-page/chicme-7th-anniv-sale' &&
            location.pathname !== '/landing-page/chicme-7th-anniv-sale/' &&
            location.pathname !== '/i/login' && <IndexAlert />
        }

        <Notifications />

        {
            location.pathname !== '/i/login' &&
            <Raffle show={ui.showLottery} onClose={() => {
                dispatch({
                    type: TOGGLE_UI_LOTTERY,
                    payload: false
                })
            }} />
        }

        <SizeChartWrapped />

        <ProductEditor
            sensors={editingProduct?.sensors}
            showEditor={!!editingProduct?.productId}
            onClose={() => { dispatch(setEditingProduct(null)) }}
            giftInfo={editingProduct?.giftInfo}
            isLuckyDraw={editingProduct?.isLuckyDraw}
            productId={editingProduct?.productId} color={editingProduct?.color} 
        />

        {
            ui.opLoading && <OPLoading isDesktop />
        }
    </div>
}