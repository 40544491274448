import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import Item, { EmptyItem } from '../item'
import CollectionCard from '../collection-card'
import SmartCard from '../smart-card'

import { withList as withPage } from '../../hocs/pagination-list'
import { withList } from '../../../../../components/list'
import Pagination from '../../../../components/pagination'

import SimpleItem from '../item/simple-item'
import FlashItem from '../item/flash-item'

export const Products = props => {
	useStyles(classes)
	const { products, column, sensors, product_type, filter, isFlash } = props
	return <div className={props?.className || classes.Listing}>
		{
			products?.map((product, index) => {
				if (product.isCollection) {
					return <CollectionCard sensors={sensors} position={index} collection={product} key={product.id} />
				}
				if (product.isSmartCard) {
					return <SmartCard sensors={sensors} position={index} smartCard={product} key={product.id} />
				}
				return isFlash ? <FlashItem filter={filter} product_type={product_type} sensors={sensors} column={column} position={index} product={product} key={product.id} /> : <Item filter={filter} product_type={product_type} sensors={sensors} column={column} position={index} product={product} key={product.id} />
			})
		}
	</div>
}

export const SimpleProducts = props => {
	useStyles(classes)
	const { products, column, sensors, product_type, giftInfo, isLuckyDraw, isAddOn } = props
	return <div className={props?.className || classes.Listing}>
		{
			products?.map((product, index) => {
				if (!product.isCollection && !product.isSmartCard) {
					return <SimpleItem product_type={product_type} isLuckyDraw={isLuckyDraw} isAddOn={isAddOn} giftInfo={giftInfo} sensors={sensors} column={column} position={index} product={product} key={product.id} />
				}
			})
		}
	</div>
}

export const EmptyProducts = props => {
	useStyles(classes)
	const { count = 20 } = props
	return <div className={props?.className || classes.Listing}>
		{
			Array.from(new Array(count)).map((_, index) => <EmptyItem key={index} />)
		}
	</div>
}

const ListingProducts = withList(Products)
const PageProducts = withPage(Products, Pagination)

export const PageListProducts = class extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return <PageProducts {...this.props} />
	}
}

export default class extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		return <ListingProducts {...this.props} />
	}
}