import useStyles from 'isomorphic-style-loader/useStyles'
import React, { useEffect, useMemo, useState } from 'react'
import classes from './style.module.scss'
import { Iconfont } from "../../../../../../../components/icon/iconfont";
import deeplink from "../../../../../../../../utils/deeplink";
import { BlackMask } from '../../../../../../../components/mask';
import { useIntl, FormattedMessage } from 'react-intl';
import DisplayTransition from '../../../../../../components/transitions/display-transition';
import { SimpleProducts } from '../../../../../listing/components/list';
import { action_add_modal_gift_list, action_fetch_gifts_info_message } from '../../../../../../../../store/actions/pages/collection';
import { useDispatch, useSelector } from 'react-redux';
import { withList } from '../../../../../../../pages/details/hoc/withList';
import qs from 'query-string'
import { getSensorsUrl } from '../../../../../../../utils/sensor';
import CountDown from '../../../../../../../components/count-down';

const GiftModalList = withList(props => {
    useStyles(classes)
    const { innerRef, sensors, isLuckyDraw, isAddOn } = props;
    const pageCollection = useSelector(state => state.pageCollection);
    const { modalGifts, giftInfo } = pageCollection;
    // console.log('pageCollection',pageCollection)


    return <div className={classes.Bd} ref={innerRef}>
        {
            modalGifts?.products?.length > 0 && <SimpleProducts
                products={modalGifts?.products}
                giftInfo={giftInfo}
                product_type="gift"
                column="gift"
                sensors={sensors}
                isLuckyDraw={isLuckyDraw}
                isAddOn={isAddOn}
            />
        }

        {
            modalGifts?.loading && !modalGifts?.finished && <div className={classes.Loading}>
                <FormattedMessage id="loading" defaultMessage="Loading" />
            </div>
        }

        {
            modalGifts?.finished && <div className={classes.Finished}>
                <FormattedMessage id="finished" defaultMessage="Finished" />
            </div>
        }
    </div>
})

const GiftsModalItem = props => {
    useStyles(classes)
    const { onClose, giftsData, luckyDrawData } = props;
    const dispatch = useDispatch();
    const pageCollection = useSelector(state => state.pageCollection);
    const { modalGifts, giftInfo } = pageCollection;
    let { giftCollectionId } = luckyDrawData || qs.parse(giftsData?.deepLink?.params?.[2])

    useEffect(async () => {
        if (giftCollectionId) {
            if (!(modalGifts?.skip > 0)) {
                await dispatch(action_add_modal_gift_list(giftCollectionId, { skip: modalGifts?.skip }));
            }
            if (!luckyDrawData) {
                await dispatch(action_fetch_gifts_info_message());
            }
        }

    }, []);

    const onScroll = async () => {
        await dispatch(action_add_modal_gift_list(giftCollectionId, { skip: modalGifts?.skip }));
    }

    return <React.Fragment>
        <div className={classes.Hd}>
            <Iconfont className={classes.CloseIcon} onClick={onClose}>&#xe69a;</Iconfont>
            <p className={classes.Title1}><FormattedMessage id="free_gift" defaultMessage="Free gifts" /></p>
            {
                !luckyDrawData &&
                <p className={classes.Title2}>{giftInfo?.giftWarnMsg}</p>
            }

        </div>

        <GiftModalList request={onScroll} isLuckyDraw={!!luckyDrawData} isAddOn={giftsData?.isAddOn} sensors={giftsData?.sensors} loading={modalGifts?.loading} finished={modalGifts?.finished} />
    </React.Fragment>
}

const GiftsModal = props => {
    useStyles(classes)
    const { giftsData, onClose, zIndex, luckyDrawData } = props;

    return <React.Fragment>
        <DisplayTransition in={!!(giftsData || luckyDrawData)} className={classes.GiftsModal} style={{ zIndex: zIndex ? zIndex : '9' }}>
            <div>
                <GiftsModalItem {...props} />
            </div>
        </DisplayTransition>

        {
            !!giftsData && !luckyDrawData && <BlackMask onClick={onClose} style={{ zIndex: 8 }} />
        }
    </React.Fragment>
}

const ProductOffInfoModal = (props) => {
    const { show, onClose, promList, couponList, productId, onShowGifts } = props

    const intl = useIntl();

    useEffect(() => {
        if (window.GeekoSensors) {
            promList?.forEach?.((p, index) => {
                window.GeekoSensors.Track('PitPositionExposure', {
                    resourcepage_title: 'product',
                    resource_position: index + 1,
                    resource_type: '2',
                    resource_content: p?.description
                })
            })
        }
    }, [])

    const go = (url, { title, description, id, deepLink }, position) => {
        // deepLink = {
        //     "type":"38",
        //     "params":["1u6g5A8H8j2o1f9Z2g2M60H4j","gifts","isGifts=1&giftCollectionId=1u6g5A8H8j2o1f9Z2g2M60H4j"]
        // };

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('PitPositionClick', {
                resourcepage_title: 'product',
                resource_position: position,
                resource_type: '2',
                resource_content: description
            })
        }

        if (deepLink?.type == 38 && deepLink?.params?.[2]?.indexOf?.('isGifts=1') >= 0) {
            onShowGifts({
                deepLink,
                sensors: {
                    resourcepage_title: 'product',
                    resource_position: position,
                    resource_type: '2',
                    resource_content: description
                }
            });
            onClose()

            if (window.GeekoSensors) {
                window.GeekoSensors.Track('FreeGiftClick', {})
            }
            return;
        }
        // else {
        //     // 是add on活动
        //     onShowGifts({
        //         deepLink,
        //         isAddOn: true,
        //         sensors: {
        //             resourcepage_title: 'product',
        //             resource_position: position,
        //             resource_type: '2',
        //             resource_content: description
        //         }
        //     })
        // }

        onClose()

        let href;
        if (url?.indexOf("?") != -1) {
            if (url?.split("?")[1]) {
                href = url + '&productId=' + productId + '&activityId=' + id
            } else {
                href = url + 'productId=' + productId + '&activityId=' + id
            }
        } else {
            href = url + '?productId=' + productId + '&activityId=' + id
        }

        window.location.href = getSensorsUrl(href, {
            resourcepage_title: 'product',
            resource_position: position,
            resource_type: '2',
            resource_content: description
        })
    }

    return (
        <React.Fragment>
            <div className={classes.offMask}>
                <div>
                    <Iconfont className={classes.offMaskCloseIcon} onClick={onClose}>&#xe7c9;</Iconfont>
                    {
                        promList && promList.length > 0 &&
                        <>
                            <div className={classes.promotionTitle}>
                                <FormattedMessage id="promotions" />
                            </div>
                            <div className={classes.promotionList}>
                                {
                                    promList.map((p, i) => (
                                        i == 0 ?
                                            <div className={classes.promotionItem} key={i} onClick={() => go(deeplink(p?.deepLink, "pc"), p, i + 1)}>
                                                <div className={classes.tag}>{p.title}</div>
                                                <div className={classes.promotionDes}>{p.description}</div>
                                                <Iconfont className={classes.toCollectionIcon} >&#xe694;</Iconfont>
                                            </div> :
                                            <div className={classes.promotionItem} key={i} onClick={() => go(deeplink(p?.deepLink, "pc"), p, i + 1)}>
                                                <div className={classes.tag}>{p.title}</div>
                                                <div className={classes.promotionDes}>{p.description}</div>
                                                <Iconfont className={classes.toCollectionIcon} >&#xe694;</Iconfont>
                                            </div>
                                    ))
                                }
                            </div>
                        </>
                    }

                    {
                        false && couponList && couponList.length > 0 &&
                        <>
                            <div className={classes.promotionTitle}>
                                <FormattedMessage id="coupons" />
                            </div>
                            <div className={classes.couopnList}>
                                {
                                    couponList.map((c, i) => (
                                        <div className={classes.couponItem}>
                                            <div className={classes.couponLeft}>
                                                <div className={classes.couponMain}>
                                                    {
                                                        c.name &&
                                                        <div className={classes.couponName}>{c.name}</div>
                                                    }
                                                    {
                                                        c.condition &&
                                                        <div className={classes.couponContent}>{c.condition}</div>
                                                    }
                                                    {
                                                        c.description &&
                                                        <div className={classes.couponContent}>{c.description}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={classes.couponRight}>

                                            </div>



                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }

                </div>
            </div>

            {
                show && <BlackMask onClick={onClose} />
            }

        </React.Fragment>
    )
}

const OffInfoItem = props => {
    useStyles(classes)
    const { prom } = props

    return (
        <div className={classes.tag}>{prom?.subTitle || prom?.name}{prom?.condition ? ' '+prom?.condition: ''}</div>
    )
}

const ProductOffInfo = (props) => {
    useStyles(classes)
    const { promList, productId, serverTime, setShowProm } = props
    const intl = useIntl()

    // const [showMore, setShowMore] = useState(false)

    const [giftsData, setGiftsData] = useState(null);
    const [showLimitDiscount, setShowLimitDiscount] = useState(true)

    const limitDiscountProm = useMemo(() => {
        return promList?.find(p => p?.promotionType == "8" && p?.activeType == "2") || null
    }, [promList])

    const leftPromList = useMemo(() => {
        return promList?.filter(p => !(p?.promotionType == "8" && p?.activeType == "2"))
    }, [promList])

    const countDownNum = useMemo(() => {
        let end = limitDiscountProm?.endTime || 0
        let start = serverTime || 0
        return end - start
    }, [limitDiscountProm])

    const countDownEndFunc = () => {
        setShowLimitDiscount(false)
        let href = window.location.href, newHref
        if (href?.indexOf("utm_source") != -1) {
            newHref = window.location.href?.replace("utm_source", "utm_source_expired")
            window.location.replace(newHref)
            return
        } else {
            window.location.reload()
            return
        }
    }

    return <React.Fragment>
        {
            ((limitDiscountProm && showLimitDiscount && countDownNum > 100)) &&
            <div className={classes.LimitDiscountBox}>
                <span>{limitDiscountProm?.subTitle || intl.formatMessage({ id: 'limited_discount', defaultMessage: 'Limited Discount' })}</span>
                <div>
                    <span>
                        <FormattedMessage id="ends_in" defaultMessage="Ends In" />
                    </span>
                    <CountDown
                        offset={countDownNum}
                        callback={() => countDownEndFunc()}
                        showHour={true}
                        showLabel={true}
                        style={{ marginLeft: 5 }}
                        dotStyle={{ margin: '0 2px' }}
                        numberStyle={{ display: 'inline-block', lineHeight: '20px' }} />
                </div>
            </div>
        }

        {
            leftPromList?.length > 0 &&
            <div className={classes.OffInfoListBox}>
                <div className={classes.OffInfoItem} onClick={() => setShowProm(true)}>
                    <Iconfont className={classes.Icon}>&#xe6b4;</Iconfont>
                    <div className={classes.flexBox}>
                        <div>
                            {
                                leftPromList?.map?.((p, i) => (
                                    <OffInfoItem prom={p} key={i} />
                                ))
                            }
                        </div>

                        <Iconfont className={classes.icon}>&#xe694;</Iconfont>
                    </div>

                </div>

                {/* {
                    showMore &&
                    <ProductOffInfoModal
                        show={showMore}
                        promList={leftPromList}
                        couopnList={[]}
                        productId={productId}
                        onClose={() => setShowMore(false)}
                        onShowGifts={data=>setGiftsData(data)}
                    />

                } */}

                <GiftsModal giftsData={giftsData} onClose={() => setGiftsData(null)} />
            </div>
        }

    </React.Fragment>
}

export {
    ProductOffInfo,
    GiftsModal
}