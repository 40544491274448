export default {
    sort: 'Trier',//CM0768
    filter: 'Filtre',//CM0930
    loading: 'chargement',//CM0380
    finished: 'Achevé',//
    home: 'Accueil',//CM0141
    get: 'Get',//
    currency: 'Devise',//CM0345
    language: 'Langue',//CM0655
    order_tracking: 'Suivi de la commande',//CM0825
    shipping_info: 'Informations de livraison',//CM0837
    return_policy: 'Politique de retour',//CM0105
    privarcy_policy: 'Politique de confidentialit\u00e9 et de s\u00e9curit\u00e9',//CM0835
    get_app: 'Obtenez l\'application {app}',//
    color: 'COULEUR',//CM0143
    size: 'TAILLE',//CM0142
    add_to_bag: 'AJOUTER AU PANIER',//
    clear: 'Effacer',//CM0855
    apply: 'Appliquer',//CM0545_1
    search: 'chercher',//
    i_shipping_for: 'Je fais des achats pour...',//CM0112
    cancel: 'Annuler',//CM0765
    hot_search: 'Recherche fr\u00e9quente',//CM0705
    items: 'Articles',//CM1032
    view_more: 'Afficher plus', //CM0153
    sold_out: 'Brad\u00e9',//CM0366
    view_all: 'Tout afficher',//CM0797
    thanks_subscribe: 'Merci de vous \u00eatre abonn\u00e9\u00a0!',//CM0956
    thanks_enjoy: 'V\u00e9rifiez votre adresse \u00e9lectronique pour connaitre votre nom d\u2019utilisateur et votre mot de passe.',//CM0957
    subscribe_title: 'Inscrivez-vous pour des offres exclusives!',//
    subscribe: 'SOUSCRIRE',//CM0091
    smufcme:'Souscrire aux courriels {brand} (vous pouvez vous d\u00e9sabonner \u00e0 tout moment).',//CM0967
    c_info: '{brand} Information',//CM0830
    about_us: '\u00c0 propos de nous',//CM0095
    affiliate: 'Soci\u00e9t\u00e9 affili\u00e9e',//CM0823
    blogger_program: 'Programme de blogueur',//CM0824
    help_support: 'Aide et assistance',//CM0831
    shipping_policy: 'Politique de livraison',//CM0106
    faq: 'FAQ',//CM0099
    order_tracking: 'Suivi de la commande',//CM0825
    coupons: 'Coupons',//CM0188
    bonus_points: 'Points bonus',//CM1035
    customer_care: 'Service client',//CM0832
    contact_us: 'Nous contacter',//CM0097
    return_policy: 'Politique de retour',//CM0105
    out_of_stock: 'En rupture de stock',//CM0621
    wholesale_policy: 'Politique de gros',//CM0108
    drop_shipping_policy: 'Politique du dropshipping',//CM0109
    i_p_rights: 'Intellectual Property Rights',
    privacy_policy: 'Politique de confidentialit\u00e9 et de s\u00e9curit\u00e9',//CM0835
    term_notice: 'Mention sur les termes et conditions g\u00e9n\u00e9rales',//CM0836
    category: 'Cat\u00e9gorie', //CM1019
    new_in: 'Nouveaut\u00e9s',//CM0001
    bag: 'sac',//CM1021
    account: 'Compte',
    flash_sale: 'Bonnes affaires',//CM0772
    ends_in: 'Se termine dans', //CM1009
    free_gift: 'Cadeaux gratuits',
    multi_add: 'Désolé, vous ne pouvez choisir qu\'un seul article comme cadeau gratuit, si vous souhaitez ajouter un autre article, il remplacera le cadeau gratuit que vous avez déjà ajouté.',
    learn_more: 'Apprendre encore plus',
    email: 'E-mail',
    recommend: 'Recommander',
    confirm: 'confirmer',
    get_the_look:"obtenir le look", //CM1187
    previous:"pr\u00e9c\u00e9dent", //CM1188
    next:"suivant", //CM1189
    beauty:"Beaut\u00e9", //CM0322
    lingerie:"Lingerie",  //CM0135
    shop_the_look:"Achetez le look",
    good_to_know:"bon \u00e0 savoir", //CM1131
    time_left:"Temps restant", //CM0363
    limited_titme:"Dur\u00e9e limit\u00e9e", //CM0907
    flash_sale:"Vente flash", //CM0451
    new:"NOUVEAU", //CM0456
    more:"Suite",
    qty:"Quantit\u00e9", //CM0200
    reviews:"les commentaires", //CM0370
    recommend:"Recommander", //CM1034
    you_can_match_with:"Vous pouvez assortir \u00e0", //CM1012
    related_searches:"Recherches associ\u00e9es", //CM1000
    too_small:"Trop petit", //CM0373
    ture_to_size:"Taille appropri\u00e9e", //CM0372
    to_large:"Trop grand", //CM0371
    translare_to:"Traduire en", //CM1128
    translate:"traduire", //CM1127
    overall_fit:"Coupe globale",
    share_the_product_to:"Partager le produit avec",
    description:"la description", //CM1130
    ingredients:"Ingr\u00e9dients", //CM1132
    estimated_time:"Heure d'arriv\u00e9e estim\u00e9e", //CM1031
    model_stature:"Statut du mod\u00e8le", //CM0367
    model_wear:"Habillement de mannequin", //CM1015
    height:"Hauteur", //CM0971
    bust:"Poitrine", //CM0172
    waist:"Taille", //CM0173
    hips:"Hanche", //CM0174
    shipping_information:"Informations d\u2019exp\u00e9dition", //CM0149
    sizing_help:"Aide de taille", //CM1014
    product_details:"d\u00e9tails du produit", //CM1013
    you_may_also_likes:"VOUS POURRIEZ AUSSI AIMER", //CM0154
    if_the_item_comes_back:"Si l'article revient en stock dans les 30 jours, nous vous enverrons un e-mail.", //CM1136
    you_haveArrival_reminder:"Vous \u00eates d\u00e9j\u00e0 abonn\u00e9 au rappel d'arriv\u00e9e", //CM1125
    sold_out_notification:"L'article est \u00e9puis\u00e9, cliquez sur m'informer pour une notification de r\u00e9approvisionnement", //CM1126
    un_subscribe:"DESINSCRIPTION", //CM1123
    notify_me:"M'AVERTIR", //CM1124
    add_to_cart:"Ajouter au panier", //CM0145
    product_measurements:"Mesures du produit", //CM1183
    view_size_information:"Voir plus d'informations sur la taille", //CM1129
    beauty_not_supported:"Le retour et l'\u00e9change de marchandises ne sont pas pris en charge.", //CM1134
    easy_return:"Facile \u00e0 retourner", //CM1011
    find_simlar:"Trouver similaire", //CM1135
    babies:"B\u00e9b\u00e9s",
    babies_look:"B\u00e9b\u00e9 regarde",
    shipping_method:"Méthode d'Expédition",
    ship_current_address:"Cet article ne peut pas \u00EAtre exp\u00E9di\u00E9 \u00E0 l'adresse actuelle", //CM1184
    show:"Montrer",
    index_subscribe_agree:"En vous abonnant, vous acceptez notre",
    privacy_cookie:"Confidentialit\u00e9 et cookies",
    terms_conditions:"termes et conditions",
    and:"et",
    register:"S'inscrire", //CM1204
    us_address_deliverd:"On estime que {value} sera livrée le",
    us_address:"l'adresse aux États-Unis",
    enter_email_address:"Entrez votre adresse e-mail",
    mystery_offer:"OFFRE MYSTERE",
    get_mystery_offer:"Je ne veux pas de mon offre myst\u00e8re",
    has_subscribe_to:"L'adresse électronique a été utilisée par d'autres personnes. Veuillez indiquer une autre adresse électronique.",
    no_more_data:"Aucune autre donn\u00E9e", //CM0381
    add:"ajouter",
    shopping_for:"Je fais des achats pour", //CM0112
    buy_now: 'acheter maintenant', //CM0665
    visual_search:"Recherche visuelle",
    upload_image_search_photo:"Prenez une photo ou t\u00e9l\u00e9chargez une image pour trouver des articles similaires.",
    recommend:"Recommander", //CM1034
    new_arrivals:"Nouveaux Arrivages", //CM0340
    price:"Prix", //CM0946
    take_a_photo:"Prendre une photo",
    upload_a_image:"T\u00e9l\u00e9charger une image",
    photo_upload_image:"Vous pouvez maintenant rechercher l'article que vous voulez en prenant une photo ou en t\u00e9l\u00e9chargeant une image",
    not_match_fund:"Pas de correspondance trouv\u00e9e",
    please_try_clearer_image:"Veuillez essayer une image plus claire ou mettre le produit que vous souhaitez rechercher au milieu de l'image",
    try_text_to_search:"Essayez le texte pour rechercher l'article que vous voulez",
    try_text_search:"Essayez la recherche de texte",
    try_again_with_image:"Essayez \u00e0 nouveau avec l'image",
    or:"ou", //CM0547
    low_price:"Bas prix",
    high_price:"Prix \u00e9lev\u00e9",
    register_success_message:"V\u00E9rifiez votre bo\u00EEte de r\u00E9ception pour votre {coupon} et {point} de r\u00E9compense ! Nous avons envoy\u00E9 \u00E0 {email}un e-mail de confirmation. Le lien n'est valable que 72 heures.", //CM1111
    register_success_message_phone: "V\u00E9rifiez votre bo\u00EEte de r\u00E9ception pour votre {coupon} et {point} de r\u00E9compense !",
    sign_in:"Se connecter", //CM0354
    or_join_with:"Ou rejoignez", //CM1236
    captcha_not_empty:"Le CAPTCHA ne peut pas \u00eatre vide", //google
    forget_password:"Avez-vous oubli\u00E9 votre mot de passe\u00A0", //CM0458
    email_address:"Adresse \u00E9lectronique", //CM0272
    password:"Mot de passe", //CM0432
    six_characters_min:"6 caract\u00E8res minimum", //CM1100
    twenty_characters_max:"20 caract\u00E8res maximum", //CM1101
    no_emojis:"Pas d'emojis", //CM1102
    belong_get_points_500:"500 points vous appartiendront imm\u00E9diatement", //CM1178
    finshed_register_shop:"Compl\u00E9tez l'inscription et obtenez des points de r\u00E9compense pour magasiner\u00A0", //CM1179
    register_privacy_policy:"J'accepte les {term} et {privacy}", // CM1116 CM0462
    term_of_services:"Conditions d\u2019utilisation", //CM0103
    privacy_policy_only:"D\u00E9claration de confidentialit\u00E9", //CM0104
    create_account:"Cr\u00E9er un compte", //CM0491
    coupon:"Coupon", //CM0543
    points:"Points", //CM1202
    welcome_to:"Bienvenue chez", //CM1107
    registration_success:"Enregistrement r\u00E9ussi", //CM1108
    reset_password:"R\u00E9initialiser le mot de passe", //CM0443
    please_send_email_auto_matically:"Veuillez entrer l'adresse e-mail que vous avez enregistr\u00E9e chez nous. Un e-mail de r\u00E9cup\u00E9ration de mot de passe vous sera automatiquement envoy\u00E9.", //CM1089
    send_email:"Envoyer un e-mail", //CM1090
    link_rest_password_to:"Le lien pour r\u00E9initialiser le mot de passe a \u00E9t\u00E9 envoy\u00E9 \u00E0", //CM1091
    create_new_password_link_easy:"Pour cr\u00E9er votre nouveau mot de passe, cliquez simplement sur le lien dans l'e-mail que nous vous avons envoy\u00E9 - facile", //CM1092
    check_agin_enter_email_send:"Vous ne l'avez pas re\u00E7u ? V\u00E9rifiez vos courriers ind\u00E9sirables ou cliquez sur le lien ci-dessous et nous vous en enverrons un nouveau.", //CM1093
    still_online_help:"Si vous ne le trouvez toujours pas, cliquez sur \"Aide en ligne\" pour obtenir de l'aide", //CM1094
    three_hours_expires:"Attention : le lien expire dans trois heures", //CM1095
    resend_email:"R\u00E9-envoyer l'email", //CM1115
    advanced_messenger:"Service de messagerie avanc\u00E9", //CM0948
    discount_deals_weekly:"Vous recevrez chaque semaine des codes de r\u00E9duction et des offres sp\u00E9ciales", //CM0950
    response_order_infomation:"Vous pouvez recevoir une r\u00E9ponse automatique concernant les informations sur les commandes", //CM0951
    online_custoer_service:"Vous pouvez obtenir un service client\u00E8le en ligne", //CM0952
    continue:"Continuer", //CM0953
    password_incorrect:"Mot de passe incorrect ! Mot de passe oubli\u00E9?", //CM1097
    try_again:"R\u00E9essayez", //CM1099
    emaul_no_register_now:"Ce compte de messagerie n'est pas enregistr\u00E9, voulez-vous vous inscrire maintenant", //CM1103
    no_thanks:"NON, MERCI", //CM1104
    register_has_login_now:"Ce compte d\u2019adresse e-mail a \u00E9t\u00E9 enregistr\u00E9, voulez-vous vous connecter maintenant?", //CM1105
    log_in_now:"Connectez-vous maintenant", //CM1106
    view_on_app:"Afficher sur l'application", //google
    today_no_change:"Vous n'avez plus de chances aujourd'hui, allez faire du shopping !", //CM1140
    go_shopping:"Allez faire les courses", //CM1141
    player_again:"Rejouez", //CM1142
    congratulations:"F\u00E9licitations\u00A0", //CM0940
    please_try_again:"Oups, veuillez r\u00E9essayer\u00A0", //CM1145
    go:"ALLEZ", //CM1180
    my_prize:"Mes prix", //CM1081
    rules:"R\u00E8gles", //CM0777
    download_app:"T\u00E9l\u00E9charger l'application", //CM1082
    expire_in:"Expire dans", //CM1250
    please_select_size:"Veuillez choisir une taille", // google
    ok:"D'accord",// google
    my_prizes_rules:"Mes prix et r\u00E8gles", //CM1192
    you_have:"Vous avez", //CM1038
    changes:"Chances", //CM1039
    customer_services_prizes:"Veuillez prendre une capture d'écran et {value} pour contacter le service client pour votre prix", //google
    click_here:"cliquez ici", //CM1064
    first_lottery_login:"Veuillez d'abord vous connecter pour jouer", // google
    count_items: "{total} articles",
    today_no_change_for_app:"Vous avez épuisé toutes vos chances aujourd'hui. Obtenez 1 chance supplémentaire sur {name} APP!",
    email_entered_try_again:"L'email que vous avez saisi n'est pas valide. Veuillez vérifier votre adresse électronique et réessayer.",
    you_are_registerd:"Vous êtes déjà inscrits",
    subscribe_to_get_lottery_change:"*L'offre ne peut être utilisée que par les nouveaux abonnés. En vous inscrivant, vous acceptez de recevoir des e-mails marketing récurrents de {name} à l'adresse e-mail utilisée lors de votre inscription.",
    invalid_password:"Mot de passe incorrect",
    lucky_draw:"LUCKY\nDRAW",
    lucky_draw_nobreak:"LUCKY DRAW",
    fill_in_password:"Remplir le mot de passe",
    subtext: "Nous aimerions vous envoyer des notifications pour les derni\u00E8res nouvelles et mises \u00E0 jour.",//CM1008
    no_thanks: "Non, merci",//CM1117
    allow: "Permettre", //CM1118
    draw_now:"Dessiner\nMaintenant",
    take_simlar_item_image:"Prenez une photo ou téléchargez une image pour trouver des articles similaires.",
    camera:"CAMÉRA",
    images:"IMAGES",
    shop_similar:"Acheter similaire",

    privacy_request_center: "Centre de demande de confidentialité",
    valid_code_sent: "Le code de vérification a été envoyé, veuillez vous rendre sur votre e-mail pour vérifier le code de vérification.",
    sending: 'Sending',
    send: 'Envoyer',
    submit_successfully: 'Soumis avec succ\u00e8s',
    thanck_request: 'Merci pour votre requête. Un représentant examinera votre demande et vous contactera à l\'adresse e-mail que vous avez fournie.',
    country_is_required: 'Veuillez sélectionner votre pays/région.',
    name_is_required: 'S\'il vous plait entrez votre nom entier.',
    account_is_required: 'L\'e-mail que vous avez entré est invalide.',
    account_is_invalid: 'L\'e-mail que vous avez entré est invalide.',
    code_is_required: 'Veuillez entrer le code de vérification.',
    description_is_required: 'Veuillez décrire votre probl\u00e8me en détail.',
    country: 'Pays/Région',
    place_holder_country: 'Veuillez choisir votre pays/région.',
    full_name: 'Nom complet',
    place_holder_fullname: 'Le nom complet doit contenir entre 2 et 100 caract\u00e8res.',
    your_account: 'Votre compte {webname}',
    place_holder_account: 'Nous aurons besoin que vous fournissiez l\'adresse e-mail associée à votre compte afin de trouver les données personnelles que nous avons stockées dans notre syst\u00e8me.',
    verification_code: 'Code de vérification',
    detailed_description: 'Description détaillée',
    place_holder_description: 'Veuillez entrer une description détaillée',
    i_agree_to_the_privacy_policy: 'J\'accepte que {webname} collecte et utilise les données que je soumets ci-dessus conformément à la politique de {policy} traiter ma demande.',
    submit: 'Submit',
    next_step: 'Next Step',
    privacy_policy: 'confidentialité et de cookies pour',
    verify_email_comfirm:"Veuillez vous assurer que l'email que vous avez entré {value} est correct. Si vous devez continuer l'enregistrement, veuillez cliquer sur confirmer.",
    how_to_pay:"Comment payer",
    share_to:"Partager à",
    copy_link:"Copier le lien",
    copy_success:"Copie réussie",
    get_draw_chance:"obtenir 1 chance supplémentaire",
    support_pre_sale_title: 'pr\u00E9-vente', //CM0860
    support_pre_sale: 'Si vous avez des questions avant d\u2019effectuer un achat, discutez avec nos op\u00E9rateurs en ligne pour obtenir plus d\u2019informations.',//CM0861
    support_after_sale_title: 'apr\u00E8s-vente',//CM0862
    support_after_sale: 'Si vous avez besoin d\u2019aide concernant les questions d\u2019apr\u00E8s-vente, veuillez soumettre un ticket.',//CM0863
    online_help: 'Assistance en ligne',//CM0915
    submit_ticket: 'Soumettre un ticket',//CM0864
    search_for: 'Je fais des achats pour...',//CM0112
    clear_all: 'Effacer tout',//CM0931
    empty_cart_description: 'Votre panier est actuellement vide',//CM0521
    you_selected: 'Vous avez s\u00E9lectionn\u00E9 {color}',//CM0475
    uploading_photo: 'Rechercher en t\u00E9l\u00E9chargeant une photo',//CM1298
    upload_photo: 'T\u00E9l\u00E9charger une photo',//CM0257
    price_low_to_high: 'Prix de bas en haut',//CM0845
    price_high_to_low: 'Prix de haut en bas',//CM0846
    shop_similar:'Acheter similaire',//CM1304
    uploading: 'Téléchargement',
    empty_filter_page: 'Oups\u00A0! Nous n\u2019avons pas trouv\u00E9 ce que vous cherchiez. Veuillez utiliser moins de filtres\u00A0!',//CM0955
    recently_search: 'B\u00FAsqueda reciente', //CM1017
    my_account: 'Mon Compte', //CM0296
    my_orders: 'Mes commandes', //CM0479
    my_message: 'Mon message',//CM1119
    my_coupons: 'Mes Coupons',//CM1120
    my_points: 'Mes points', //CM1121
    view_bag: 'AFFICHER LE PANIER',//CM0183
    sign_out: 'Se d\u00E9connecter',//CM0254
    total: 'Total',//CM0180
    check_it_in_coupons: 'Vérifiez-le dans [Mes coupons]',
    got_it: 'JE L\'AI EU',
    congratulations_get: 'Félicitations pour avoir obtenu {money}!',
    wait: 'Attendez',
    limit_coupon_unused: 'Vous avez encore des coupons limités non utilisés',
    limit_24_time: '24 HEURES SEULEMEN',
    return_to_site: 'RETOURNER AU SITE',
    get_offer:"Obtenir l'offre",
    enter_email_to_reveal_offer:'entrez votre e-mail pour révéler votre offre spéciale', //CM1256
    buy:"Achetez", //CM0242
    simply_special_price:"Scannez simplement le code QR pour installer et pr\u00E9parez-vous avec le PRIX SP\u00C9CIAL\u00A0!", //CM1209
    model:"Mod\u00E8le", // CM0970 
    size_guide:"Guide des tailles", // CM0156
    weight:"Lester",
    customer_reviews:"Avis des clients", // CM1018
    size_chart:"Tableau des tailles", //CM0476
    support:"Assistance", //CM0094
    payment_method:"Mode de paiement", //CM0389
    website_rights_reserved:"{name} est une marque d\u00E9pos\u00E9e de {domain}.Tous droits r\u00E9serv\u00E9s.", //CM0834
    follow_us:"nous suivre", //CM0093
    shipping_time:"Heure d\u2019exp\u00E9dition", //CM0377
    costs:"Co\u00FBts", //CM0965
    shipping_to:"Exp\u00E9dition vers", //CM0972
    receiving_time:"Temps de r\u00E9ception", //CM0375
    precessing_time:"D\u00E9lai de traitement	", //CM0376
    products: 'Des produits',
    promotions: 'Promotions',
    go_to_bag: "ALLER AU SAC",
    total_peice_price:"Total {total} pi\u00e8ces | {price}",
    reset_qty:"Réinitialiser",
    best_sellers:"Meilleures ventes",
    best_sellers_in:"Meilleures ventes en",

    product_unavailable:'Produit actuellement indisponible',
    save:"Sauvegarder", //CM0212
    set:"Positionner",
    buy_together:"Achetez ensemble",
    add_successful:"Ajouter le succès",
    collect:"collect",
    collect_all:"tout collecter",
    free_cash: 'Cadeaux en Argent Gratuits!',
    win_100: '100%  de Taux de Gain!',
    ann_c_1: 'Chaque Utilisateur Inscrit a une chance par jour et une chance supplémentaire après Chaque Paiement!',
    ann_c_2: '*La chance quotidienne sera rafraîchi à 00:00 UTC, du 17 au 23 août.',
    counpon_has_sent: '{coupon} a été envoyé à votre portefeuille.',
    expire_content: 'Expirer dans 24h!!! Maintenant utilisez-la à la Caisse! <br/> * Ne le utilisez pas pour déduire les frais d’expédition.',

    lucky_winner: 'GAGNANT CHANCEUX',
    view_prizes:"Voir les prix",
    continue_shopping: 'Continuer vos achats',
    go_wallet: 'Vers le portefeuille',
    open:'Ouvrir',
    shopping: 'Achats',
	check: 'Vérifier',

    day1:'1er jour',
    day2:'2ème jour',
    day3:'3ème jour',
    day4:'4e jour',
    day5:'5ème jour',
    day6:'6e jour',
    day7:'7e jour',
    specialGifts: 'Cadeaux spéciaux',
    specialGift: 'CADEAU SPÉCIAL',
    today: 'Aujourd \'hui',
    checkInSuccess: 'Enregistrez-vous avec succès',
    checkDays: 'Vous vous êtes enregistré depuis {day} jours.',
    checkDay: 'Vous vous êtes enregistré depuis {day} jour.',
    checkRules: 'Règles d\'enregistrement d\'anniversaire',
    openGift: ' Appuyez pour ouvrir le cadeau spécial!',
    openNotificationTip: 'Nous ne sommes pas en mesure de vous envoyer des notifications. Veuillez accéder aux paramètres pour l\'ouvrir.',
    checkDaysLeft: 'Vous vous êtes enregistré pendant {daysHave} jours consécutifs. Enregistrez-vous pendant jours de plus pour obtenir plus de surprise.',
    remindMe: 'Rappelez-moi',
    downloadNow: 'Télécharger maintenant',

    alreadyChecked: 'Vous vous êtes déjà enregistré aujourd\'hui',
    checkedNow: '50 000+ CADEAUX, DÉPÊCHEZ-VOUS !!!',
    checkInNow: 'Enregistrez-vous maintenant',
    gift: 'Cadeau',
    v_shop:"V-Shop",
    comments:"Commentaires",
    chart_ask_question:"Discutez ou posez des questions",
    tap_mystery_gift: 'Appuyez pour ouvrir le cadeau mystère!',
    mystery_gift: 'Cadeau mystère!',
    addtohometext: 'Ajouter {site} \u00E0 la page d\u2019accueil',
    // sizechart
    inches:'Pouce',
    centimeters:'cm',
    sizeconversation:'Taille Conversation',
    size:'Taille',
    bust: "buste",
    waist:"taille",
    hip: "hanche",
    length:"longueur",
    size_chart:"Tableau des tailles",
    size_help: "Aide à la taille",
    fit_type:"Type d'ajustement",
    stretch:"étirer",
    product_measurements:"Mesures du produit",
    body_measurements:"Mesures corporelles",
    bust_size: "buste",
    waist_size:"Tour de taille",
    hip_size:"Taille des hanches",
    your_bust:"Votre buste",
    your_waist:"Votre taille",
    your_hips:"Vos hanches",
    measurements: 'Nous vous suggérons fortement de prendre vos mesures et de les utiliser comme référence lors de la sélection d\'une taille d\'articles. Mesurez-vous en suivant le guide ci-dessous.',
    bust_note: "Mesurez sous vos bras autour de la partie la plus large de votre buste. Assurez-vous de garder le ruban à niveau !",
    waist_note: "Assurez-vous que le ruban à mesurer est bien ajusté lorsque vous mesurez autour de la partie la plus étroite de votre taille naturelle.",
    hip_note: "Tenez-vous debout, les pieds joints, et mesurez autour de la partie la plus large de vos hanches.",
    length_note: "Pour mesurer la longueur de votre pied, asseyez-vous sur une chaise et placez le pied à plat sur une feuille de papier. Tracez le pied en tenant un crayon verticalement et en décrivant le pied. À l'aide d'une règle, mesurez la distance depuis le bas du talon jusqu'au bout de l'orteil le plus long. Enregistrez le résultat en pouces et en centimètres et utilisez ces mesures pour trouver votre taille dans le tableau.",
    product_measurements_message:"* Ces données ont été obtenues en mesurant manuellement le produit, elles peuvent être décalées de 1-2 CM.",
    measure_product_size:"Comment mesurer la taille du produit ?",
    body_measurements_message:"* En fonction de votre morphologie et de vos habitudes vestimentaires, les tailles ci-dessus sont fournies à titre indicatif uniquement.",
    measure_body_size:"Comment mesurer votre corps ?",
    measure_body_size_bust:"Mesurez la circonférence sur la partie la plus large de votre poitrine.",
    measure_body_size_waist:"Mesurez votre taille à l'endroit le plus fin.",
    measure_body_size_hip:"Mesurez la partie la plus large de vos hanches.",
    size_fit:"Taille et coupe",
    height_waist:"Taille poids",
    measure:"Des mesures",
    model_wear:"Usure du modèle",

    one_size: 'Taille unique',
    code:"code",

    system_update: 'Mise à jour du système',
    system_update_back: 'Afin de mieux vous servir, nous mettons à jour nos systèmes. Nous serons très bientôt de retour!',
    policy:"Politique",
    after_pay_faq: "FAQ après-paiement",
    klnara_policy_message:"Achetez maintenant, payez plus tard avec Klarna!",
    zip:"Zip",
    vip:"Vip",
    delivery:"Livraison",
    about_my_wallet:"À propos de mon portefeuille",
    about_early_bird:"À propos d'EarlyBird",
    clear_pay:"Clearpay",
    sold_percent: "{percent}% Vendu",
    purchase_limit: "Limite d'achat : {qty} quantité",

    agree_login_policy_message:"Avant de passer \u00E0 l\u2019\u00E9tape suivante, assurez-vous d\u2019accepter les Conditions d\u2019utilisation et la D\u00E9claration de confidentialit\u00E9.", //CM0856
    register_with:"S\u2019inscrire avec", //CM0463
    enjoy_services:"pour b\u00E9n\u00E9ficier des services personnalis\u00E9s,", //CM0465
    including:"notamment", //CM0466
    online_order_status:"\u00C9tat de la commande en ligne", //CM0469
    exclusive_emails:"Courriels exclusifs", // CM0470
    wishlist:"Favoris", // CM0344
    checkout_preferences:"Pr\u00E9f\u00E9rences de paiement", // CM0471
    sign_In_Facebook:"Connectez-vous avec Facebook", // CM1233
    the_counry_not_support_message:"Le pays/la région actuel(le) ne prend pas en charge l'inscription/la connexion par numéro de téléphone. Veuillez utiliser l'adresse électronique pour vous inscrire/la connexion.",
    enter_the_password:"Entrez le mot de passe",
    enter_the_code_number:"Entrez le numéro de code",
    enter_phone_number:"Entrez votre numéro de téléphone",
    incorrect_phone_number:"Numéro de téléphone incorrect",
    password_change_success:"Mot de passe modifié avec succès",
    confirm_password:"Confirmer le mot de passe",
    verification_code_error_message:"Désolé, le code de vérification saisi est incorrect ou non valide.",
    phone_register_has_already:"Le numéro de téléphone a déjà été enregistré dans un compte, connectez-vous maintenant ?",
    phone_number_no_register_now:"Ce numéro de téléphone n'est pas enregistré, voulez-vous vous enregistrer maintenant ?",
    location:"LOCATION",
    telephone_number:"NUMÉRO TÉLÉPHONIQUE",
    resend:"Renvoyer",
    use_verification_code:"Utilisez le code de vérification pour vous connecter",
    sin_in_via_password:"Se connecter via le mot de passe",
    verifycation_code_success:"Le code de vérification a été envoyé avec succès",
    confirm_your_phone_number_change:"Confirmez votre numéro de téléphone et entrez le code correct pour changer le mot de passe.",
    inconststent_password:"Mots de passe incohérents",
    register_via_telephone_number:"S'inscrire via le numéro de téléphone",
    register_via_email_address:"S'inscrire via l'adresse e-mail",
    signin_via_telephone_number:"Se connecter via le numéro de téléphone",
    signin_via_email_address:"Se connecter via l'adresse e-mail",
    almost_sold_out: "Presque épuisé",

    telephone: 'Téléphone',
    forget_your_password: 'Vous avez oublié votre mot de passe ?',
    choose_your_location: 'Veuillez sélectionner votre région',
    installment_forms: 'Formulaires de versement',
    interest_free: 'Sans intérêt',
    staging_rates: 'Les taux d’échelonnement varient selon les banques',
    accpect: 'Accpet',
    sing_receive_discount_now:"Inscrivez-vous pour recevoir les remises suivantes maintenant !",
    received_following_discount:"Vous avez reçu les Remises Suivantes",
    received_vip_exclusive_discount:"Vous avez reçu des Réductions Exclusives Pour VIP",
    pick_my_gift:"Choisissez mon cadeau",
    choose_add_your_cart:"Veuillez choisir votre cadeau préféré à ajouter à votre panier.",
    reselect_gift_your_bag:"Vous pouvez resélectionner le cadeau dans votre sac.",
    check_out: 'CHECKOUT',
    gift_to_your_bag: 'L’ensemble de Noël a été mis dans votre sac, vous pouvez passer à la caisse à tout moment avant le 6 décembre.',
    foot_length: 'Longueur du pied',
    network_error:'Erreur de réseau',
    subscription:"Souscription", //CM0089
    invalid_email: 'Die eingegebene E-Mail ist ung\u00FCltig. Bitte \u00FCberpr\u00FCfen Sie Ihre E-Mail und versuchen Sie es erneut.',

    size_conversion: 'Conversion de taille',
    international_size_reference: 'La comparaison internationale des tailles est à titre de référence seulement, la taille spécifique est toujours basée sur la taille réelle des vêtements.',
    country_size: 'Taille des {country}',
    new_register_privacy_policy:"En vous connectant à votre compte, vous acceptez nos conditions de service et notre politique de confidentialité.",
    incorrect_email:"Courriel erroné",
    enter_code_number:"Entrez le numéro de code",
    enter_the_telephone_number_or_email:"Veuillez saisir Téléphone ou e-mail",
    enter_the_verification_code:"Entrez le code de vérification",
    send_verification_code:"Un code de vérification est envoyé à {value}",

    back: 'Retour',
    free_shipping: 'LIVRAISON GRATUITE',
    all: 'Tout',
    reset: 'Réinitialiser',
    done: 'Terminé',
    price_range: 'Échelle des prix({currency})',
    price_range_only: 'Prix de gamme',
    repick: 'Repick',
    pick: 'Prendre',
    sure_delete_item: 'Êtes-vous sûr de vouloir supprimer ce produit?',
    sure_delete_items: 'Êtes-vous sûr de vouloir supprimer ce produit(s)?',
    recommend_add_item: 'Éléments complémentaires recommandés',
    free_gifts: 'Cadeaux gratuits',

    available: 'Disponible',
    not: 'Pas',
    select_anthoer_paymentmethod: 'Veuillez sélectionner un autre mode de paiement',
    sign_to_sync_bag: 'Melden Sie sich an, um Ihre Einkaufstasche zu synchronisieren',
    ship_to_different_address: 'Expédier à une adresse de livraison différente',
    address_book: 'Carnet d\'adresses',
    first_name: 'Pr\u00E9nom',
    last_name: 'Nom',
    phone_number: 'Numéro de téléphone',
    address_line_1: 'Adresse de rue',
    address_line_2: 'Unité',
    address_line_1_placeholder: 'Rue,Adresse,Nom de l\'entreprise,C/O',
    number: 'Nombre',
    neighborhood: 'Voisinage',
    city:'Ville',
    state: 'État',
    zip_code: 'Code postal',
    enter_it_manually: 'Entrez-le manuellement',
    optional: 'Facultatif',
    find_simillar: 'Trouver similaire',
    ships_to_country: 'Expédié à: {country}',
    shopping_bag: 'Sac à provisions',
    secure_payment: 'Paiement sécurisé',
    shopping_bag_empty: 'Votre panier est vide !',
    sign_in_to_view_bag: 'Connectez-vous pour voir votre panier et commencer vos achats',
    shop_now: 'Achetez maintenant',
    sign_in_register: 'Iniciar sesión / Registrarse',
    secure_checkout: 'Achat Sécurisé',
    create_account_more_discount: 'Créez un nouveau compte pour obtenir plus de réduction',
    continue_to_checkout: 'CONTINUER LA COMMANDE',
    checkout_guest_with_email: "Commander en tant qu'invité avec votre e-mail",
    guest_checkout: 'caisse d\'invité',
    checkout: 'PAIEMENT',
    move_to_wishlist: 'Placer dans la liste de souhaits',
    delete: 'Supprimer',
    move_all: 'Déplacer tout',
    you_like_fill_it: 'vous aimeriez peut-être le remplir avec',
    add_to_wish_list_confirm: 'Êtes-vous sûr de déplacer le produit vers la liste de souhaits à partir du panier?',
    apply_coupon: 'Appliquer Coupon',
    ivalid_items_title: 'Ces articles sont temporairement en rupture de stock.',
    count_items: '{total} Articles',
    add_shipping_insurance: 'Ajouter une assurance d\'expédition',
    place_order: 'Placer l\'Ordre',
    apply_points: 'Appliquer des points',
    max_available_count: 'Maximum disponible {count}',
    apply_cash: 'Appliquez de l\'argent',
    wallet: 'Porte monnaie',
    order_confirmation: 'confirmation de commande',
    shipping_address: 'ADRESSE DE LIVRAISON',
    switch_payment_method: 'Changer de mode de paiement',
    change_card_information: 'Modifier les informations de la carte',
    process_payment: 'Nous traitons votre paiement',
    do_not_leave_refresh: 'Par faveur, pas de dejes ni actualices',
    price_per_installment: '{price}/mois',
    order_summary: 'Résumé de la commande',
    shipping_insurance: 'L\'assurance d\'expédition',
    order_number: 'Numéro de commande',
    item_total: 'Total d\'Articles',
    shipping_price: 'Frais d\'expédition',
    order_total: 'Total de la commande',

    required: 'Obligatoire',
    invalid_email_address: '{email} n\'est pas une adresse e-mail valide',
    first_name_reg_default: 'Le prénom doit contenir de 1 à 40 caractères numériques non purs',
	last_name_reg_default: 'Le nom de famille doit contenir de 1 à 40 caractères numériques non purs',
    phone_reg_10_11: 'Entrez au moins 10-11 chiffres.',
	phone_reg_ae: 'Les numéros de téléphone doivent commencer par 50/52/54/55/56/58/2/3/4/6/7/9, suivis de 7 chiffres.',
	phone_reg_sa: 'Les numéros de téléphone doivent commencer par 50/53/55/51/58/59/54/56/57/11/12/13/14/16/17/811, suivis de 7 chiffres.',
	phone_reg_default: 'Format de numéro de téléphone incorrect (chiffres uniquement, pas de formatage)',
    please_enter_5_digits : 'Veuillez entrer 5 chiffres ou 5 chiffres avec un numéro à 4 chiffres (par exemple 20001 ou 20001-0000)',
	wrong_zip_code: 'Mauvais CODE POSTAL ! Veuillez nous proposer une ou deux lettres et un à deux chiffres avec un espace se terminant par un chiffre et deux lettres. (par exemple TN37 7HL,CW8 3AD)',
	wrong_address_br: 'Le format de l\'adresse est incorrect, remplissez le formulaire de [01310-000]',
    not_vaild_cpf: '{value} n\'est pas un cpf valide',

    credit_debit_card:'Carte de crédit / débit',
    billing_address: 'Adresse de facturation',
	select_a_card: 'Sélectionnez une carte',
	use_new_card: 'Utiliser une Nouvelle Carte',
    waiting_for_payment:"en attente de paiement",
	view:"voir",

    auto_search_address:'ADDRESS FINDER : Recherche par code postal, rue ou adresse',
    cant_find_address: "Vous ne trouvez pas l'adresse ?",
    select_pay_method: 'Veuillez sélectionner un mode de paiement !',
    only_left: 'Seulement restent {count}',
    pay_now: 'PAYER MAINTENANT',
    card_number: 'Numéro de carte',
    expiration_date: 'Date d\'expiration',

    confirm_change_address: ' Certains articles expédiés depuis les magasins américains ne seront pas disponibles à la caisse, êtes-vous sûr de vouloir changer d\'adresse?',
    edit: 'Modifier',
    add_new_address: 'Ajouter une nouvelle adresse',
    details: 'Détail',

    use_following_coupons: 'Vous pouvez utiliser les coupons suivants sur les articles de votre panier, veuillez les utiliser sur la page de commande.',
    cancel_info: 'Êtes-vous sûr de vouloir annuler le paiement ? Si votre commande n\'est pas payée à {countdown}, elle sera annulée.',
	continue_to_pay: 'Continuer à payer',
	confirm_cancel: 'Confirmer Annuler',
    protectAccount: 'Les détails de votre compte sont entièrement protégés et ne seront révélés à aucun tiers pour quelque raison que ce soit.',
    available: 'Disponible',
    coupons_tip: "Les coupons / crédits peuvent être utilisés à l'étape suivante",
    invalid_address: 'Format d\'adresse incorrect',

    // Me
    login_or_register:"Se connecter/S'inscrire",
    introduce_to_others:"Présentez-vous aux autres",
    sure_delete_items:"Voulez-vous vraiment supprimer le ou les éléments?",
    guest_user:"Utilisateurs invit\u00e9s",
    set_password:"D\u00e9finissez le mot de passe",
    temporay_account_set_password:"Ceci est votre compte temporaire. Configurez un mot de passe pour vous inscrire pour obtenir plus de r\u00e9duction !",
    addFavorites:'Ajoutez vos favoris \u00e0 votre liste de souhaits maintenant !',









    // Me Setting
    sure_delete_address: 'Etes-vous sûr de vouloir supprimer cette adresse',  //google
    change_successful: 'Changement réussi !',   //google
    change_failed: 'Changement échoué !',  //google
    save_successful: 'Sauvegarde réussie !',  //google
    save_failed: 'Sauvegarde échouée !',  //google
    change_email: 'Modifier l\'email',  //google
    your_contact_email: 'Votre adresse de contact / d\'abonnement :',  //google
    send_confirm_email: 'Envoyer un e-mail de confirmation',
    your_new_account: 'Votre nouveau compte',
    my_payment_options:'Mon mode de paiement',
    change_password: 'Changer le Mot de Passe', // changePassword
    about: '\u00e0 propos de',
    sure_delete_card: 'Êtes-vous sûr de vouloir supprimer cette carte de cr\u00e9dit', // remove_credit_card
    current_password:"Mot de passe actuel",
    new_password:"Nouveau mot de passe",
    must_new_password:"Confirmer le mot de passe doit être égal au nouveau mot de passe.",
    confirm_pwd: 'Confirmez le mot de passe',
    old_new_password:"L'ancien et le nouveau mot de passe ne peuvent pas être identiques",
    expired_in:"Expiré dans",
    view_rules:"Afficher les règles",
    vip_rewards:"{name} VIP BELOHNUNGEN",
    level_up_unlock:"LEVEL AUFSTEIGEN UND FREISCHALTEN",
    view_rewards:"Voir les récompenses",
    points_redeem_success:'\u00e9change r\u00e9ussi !',
    faqs:"FAQS",
    your_vip_level_to:"Votre niveau VIP est passé à",
    your_new_rewards:"allez voir vos nouvelles récompenses!",
    points_history:"Historique des points",
    expriring_soon:'Expirant Bient\u00f4t',
    how_to_get_points:'Comment obtenir des points?',
    buy_and_earn:'Achetez et gagnez',
    shop_now:'Achetez maintenant',
    review:"Revoir",
    suggestion:"Suggestion",
    refer_a_friend:'Parrainer un ami',
    survey:"Enquête",
    more_ways:'Plus de fa\u00e7ons',
    hot_use_points:'Comment utiliser les points?',
    redeem_coupon:'Utilisez le coupon',
    apply_points:"Appliquer des points \u00e0 l'achat",
    all:'Tous',
    recived:'Re\u00e7u',
    used:'Utilis\u00e9',
    expired:'Expir\u00e9',
    wishlist:'Liste de Favoris',
    delete:'Supprimer',
    unused_coupon: 'Coupons non utilisés', // unused_coupons
    expired_coupon: 'Coupons périmés', // expired_coupons
    expired_coupon_remove: 'Les coupons périmés seront retirés de la pochette de coupons après 7 jours.', // expired_coupoons_message
    yes:'Oui',
    no:'NON',
    bust_size:'Taille du buste',
    bra_size:'Taille de soutien-gorge',
    what_your_preference:'Quelle est votre préférence ?',
    large:'Large',
    small:'Petit',
    my_measurements: 'Mes mesures',
    preference_cannot_empty: "Mes préférences ne peuvent pas toutes être vides",  // my_preference_error_message
    my_preference: 'Ma pr\u00e9f\u00e9rence',
    who_you_buy_clothes_for:'our qui achetez-vous habituellement des vêtements?',   // who_do_you_ususlly
    which_catehories_you_favor:'Quelles sont vos cat\u00e9gories pr\u00e9f\u00e9r\u00e9es?', // whitch_are_your_favorite
    which_style_you_favor: 'Quels sont vos styles pr\u00e9f\u00e9r\u00e9s?', // whitch_are_your_styles
    bio: 'Biographie',
    name: 'Nom',
    first_name: 'Pr\u00e9nom', // firstName
    last_name: 'Nom', // lastName
    nick_name: 'Surnom',
    change_profile_picture:'Modifier la photo de profil',
    edit_profile: 'Modifier le Profil', // editProfile
    empty_here: "C'est vide ici.", // google
    ticket:'Billet',
    activities:'Activités',
    promo:'Promotion',
    others:'Autres',
    news:'Nouvelles',
    orders:'Commandes',
    message:'Message',
    about_web: '\u00e0 propos de {website}',
    setting: 'Paramètres',  // settings
    wallet_credit: 'Crédit porte-monnaie',
    only_apply_for: 'Applicable uniquement aux achats de {website}', // only_applicable
    wallet_history: 'Historique du porte-monnaie',
    earned: 'Gagné',
    wallet: 'Portefeuille',
    expired_on: 'Expiré le: {date}',  // expied_on
    order: 'Commander', // order_wallet
    get_more_points:"Obtenez plus de points",
    share_friend_points_shop:"Vous obtiendrez jusqu'\u00E0 <span class=\"font-red\">500 points</span>,et votre ami obtiendra jusqu'\u00E0 <span class=\"font-red\">1000 points</span> pour acheter!", // CM1158
    for_limited_time_only:"Pour un temps limit\u00E9 seulement!", // CM1159
    how_does_it_works:"Comment \u00E7a fonctionne?", // CM1161
    share_the_link:"Partagez le lien", // CM1162
    reward:"R\u00E9compense", //CM0630_1
    copy_fail: 'la copie a échoué ! essayez à nouveau', // google
    get_more_rewards:"Vous voulez obtenir plus de r\u00E9compenses?", //CM1164
    friends_invited:"Amis invit\u00E9s", // CM1167
    points_earned:"Points gagn\u00E9s", // CM1168
    share_with:"PARTAGER AVEC", // CM1170
    share_friend_max_10:"The link can be shared with {value} friends at one time", //CM1166
    email_title:"Titre de l'e-mail", //CM1155
    open_to_points_reward:"Ouvrir pour recevoir des r\u00E9compenses en points myst\u00E8res", //CM1242
    email_notes:"Notes par courrier \u00E9lectronique", //CM1156
    share_textarea_description:"{name} a tellement dincontournables! Inscrivez-vous d\u00E8s maintenant pour obtenir des points de r\u00E9compense pour magasiner chez {name}.{value}.Ne manquez pas cette chance!", // CM1243
    share_via_email:"Partager par e-mail", // CM1151
    share_extra_game_play:"Cliquez sur le lien et obtenez-moi un jeu supplémentaire", //google
    share_draw_luck_gifts:"Tirage au sort du vendredi noir de {name}. Jouez et gagnez un iPhone 13, des produits de beauté de {name}, des points bonus, des coupons et plus encore.",  //google
    success:"R\u00E9ussi", //CM0558
    order_no: 'No de Commande', // orderNo
    item:"Objet",
    track:'Piste',
    pay_now:'PAYEZ MAINTENANT', // paynow
    remaining:'Temps de paiement restant',
    repurchase:'Rachat',
    to_review:'Pour r\u00e9viser',
    add_success:'Pour r\u00e9viser',
    add_failed:'Pour r\u00e9viser',
    unpaid:'Non pay\u00e9',
    processing:'En cours',
    shipped:'Commande exp\u00e9di\u00e9e',
    returns:'Retours',
    history_orders_message:'Cliquez ici pour vérifier vos commandes précédentes.',
    order_details:'D\u00e9tails de la commande',
    your_package_is_packed:'Votre colis est en cours d\'emballage',
    it_normally_process_your_order:'Il nous faut normalement 3 à 7 jours ouvrables pour traiter votre commande.',
    your_package_signed_for:'Votre package a \u00e9t\u00e9 sign\u00e9 pour.',
    if_you_cosutomer_services:'Si vous avez des questions',
    payment_date: 'Date de Paiement',  // paymentDate
    related_order_no: 'N° de commande associ\u00e9', // relatedOrderNo
    shipping_price: 'Prix d\'exp\u00e9dition', // shippingPrice
    order_total: 'total de la Commande', // orderTotal
    insurance:"L\'assurance d\'exp\u00e9dition", // shippingInsurance
    shipping_address:'Adresse de livraison',
    billing_address:'Adresse de facturation',
    shipping_method:'M\u00e9thode d\'exp\u00e9dition',
    delivery_time:'Adresse de livraison',
    return:'Retour',
    often_bought_with:'SOUVENT ACHET\u00e9 AVEC',
    cancel_order: 'Annuler l\'Ordre', // cancelOrder
    return_label:"\u00e9tiquette de retour", // returnlabel
    confirm_cancel: 'Confirmer l\'annulation',
    continue_to_pay: 'Continuer à payer',
    you_sure_cancel:'\u00eates-vous sûr de vouloir annuler le paiement ? Si votre commande n\'est pas pay\u00e9e',
    it_will_cancel:'il sera annul\u00e9',
    select_reason:'S\u00e9lectionnez une raison',
    reason_submit_success_message:"Soumis avec succès ! Souhaitez-vous ajouter à nouveau le produit à votre panier ?    ", // google
    no_comment_order:'Vous avez des commandes non v\u00e9rifi\u00e9es, allez partager votre exp\u00e9rience pour gagner plus de points !',
    you_have_no_orders: "Vous n'avez pas encore de commandes, allez choisir vos produits pr\u00e9f\u00e9r\u00e9s !", // youHaveNoOrders
    go_shopping: 'Allez faire du shopping', // goShopping
    default: 'Par défaut',
    do_not_have_enough_points: 'Vous n\'avez pas assez de points pour utiliser ce coupon.', // points_not_enough_text
    use_points_redeem_coupon: 'Voulez-vous utiliser {points} points pour \u00e9changer ce coupon ?', // points_check_redeem_text
    redeem:'\u00e9changer', // points_redeem
    redeemed: 'Rachet\u00e9',
    use_points_to_redeem:'Utilisez des points pour \u00e9changer plus de coupons!', // use_points_redeem_coupon
    upload_image: 'T\u00e9l\u00e9charger une image',
    image_formate:'Maximum de 3 photos, seulement JPEG, GIF ou PNG.', // maxinum_three_photos
    screen_freeze:'Gel de l\'\u00e9cran',
    app_crush:'Coup de cœur pour l\'application',
    black_screen:'\u00e9cran noir',
    device_reboot: 'Red\u00e9marrage de l\'appareil', // device_rebot
    running_slowly:'Courir lentement',
    other_error:'Autre erreur',
    update_success:'Mise à jour réussie',
    question_type:'Type de question',
    time:'Temps',
    suggestion_placeholder:'D\u00e9sol\u00e9 pour le d\u00e9sagr\u00e9ment, nous allons r\u00e9soudre le problème dès que possible…', // sorry_inconvenience
    points: 'Points',
    please_check_rules:'Veuillez v\u00e9rifier la page de règles pour les règles de points de texte et d\'image',
    rating:"Notation", // google
    very_satisfied:'Très satisfait',
    satisfied:'Satisfait',
    normal:'Normal',
    bad:'Mauvais',
    poor:'Pauvre',
    order_review_placeholder:'Gagnez des points {point} pour les commentaires de plus de {wordNum} caractères... *', // google
    earn_more:'Gagnez plus de {points} points pour les commentaires avec des photos',
    my_size_infomation:'Mes informations de taille (facultatif)',
    earn_more_for_size:'Gagnez plus de {points} points en remplissant toutes vos tailles',
    comments_not_empty:"Les commentaires ne peuvent pas être vides !", // google
    order_confirmed:"Commande confirm\u00e9e", // order_confirm
    order_detail_view:'Vous pouvez voir les d\u00e9tails de la commande pour v\u00e9rifier cette commande.',
    order_confirm_success:'Commande confirm\u00e9e avec succès !',
    view_order:'Voir la commande',
    order_confirmed_enter_message: 'L\'article (s) est toujours en transit, êtes-vous sûr de vouloir cliquer sur confirmer pour le recevoir?', //google
    also_awaiting_review:'Ces commandes sont \u00e9galement en attente d\'examen',
    shop_the_reviewd_items:'Achetez les articles les plus appr\u00e9ci\u00e9s',
    add_to_bag_success:'Ajout au panier r\u00e9ussi !',
    logisticsInfo:'Informations logistiques',
    tracknum:'Num\u00e9ro de suivi',
    logisticsCompany:'Entreprise de logistique',
    trackingDetailInfo:'Retour annul\u00e9',
    currentStatus:'Statut actuel',
    cancel_return:'Annuler le retour',
    edit_return_receipt:'Modifier le reçu de retour',
    return_receipt:'Reçu de retour',
    return_record:'Enregistrement de retour',
    return_canceled:'Retour annul\u00e9',
    total_refund:'Remboursement total',
    are_you_want_cancel_return:'\u00eates-vous sûr de vouloir annuler le retour ?',
    upload_receipt:'T\u00e9l\u00e9charger le reçu',
    a_pooto_package_and_tracking_number:'Une photo du reçu de la compagnie de transport indiquant le poids du colis et le num\u00e9ro de suivi.',
    courier_company:'Courrier Company',
    select_courier_company:'Veuillez s\u00e9lectionner la soci\u00e9t\u00e9 de messagerie', // please_select_courier_company
    fillin_courier_cpmpany:'Veuillez indiquer votre soci\u00e9t\u00e9 de messagerie',
    not_be_empty:'ne peux pas \u00eatre vide',
    check_order:'Vous pouvez v\u00e9rifier les d\u00e9tails de votre commande pour voir la progression de votre retour.',
    return_logistics:'Logistique de retour',
    find_similar:'Trouver similaire',
    history_orders:'Historique des commandes',
    dont_want_rate: 'Vous \u00eates sûr de ne pas vouloir \u00e9valuer cette commande ? Vous recevrez ',

    help_you: 'Comment puis-je vous aider?',
    message_us: 'Message nous',
    tickets: 'Des billets',
    search_holder: 'Produits, livraison, paiement ...',
    search_results: 'Résultats de la recherche',
    search_count: '{count} résultats pour "{key}"',
    section_articles: 'Articles dans cette section',
    status_replied: 'Répondu',
    status_unreplied: 'Sans réplique',
    status_resolved: 'Résolu',
    last_message: 'Dernier message',
    image: 'Image',
    ticket_id: 'ID de billets',
    subject_size_color: 'Taille / Couleur Préférence',
    subject_address: 'Changer l\'adresse de livraison',
    subject_shipping: 'Statut d\'expédition ou demande d\'ETA',
    subject_wrong: 'Reçu endommagé ou mauvais article',
    subject_shippingmethod: 'Mettre à niveau la méthode',
    subject_return: 'Retour ou échange',
    payment_time: 'Temps de paiement',
    response_time: 'Temps de réponse prévu: 1 jour ouvrable',
    select_order: 'Veuillez sélectionner votre commande',
    order_status_confirmed: 'Confirmé',
    order_status_canceled: 'Annulé',
    order_status_pendding: 'en attendant',
    order_status_paid: 'Payé',
    order_status_partially_refunded: 'Partiellement remboursé',
    order_status_refunded: 'Remboursé',
    order_status_held: 'Tenu',
    rate: 'Évaluation',
    unsatisfied: 'Insatisfait',
    you_can_rate: 'Vous pouvez commenter le service client ici',
    please_rate: 'S\'il vous plaît noter mon service',
    // support:
    click:'Pour toutes les questions/réponses, veuillez cliquer sur notre',
    page:"page",
    find_nothing:"Vous ne trouvez pas la réponse que vous recherchez?",
    order_processing: "Traitement des commandes",
    tracking:"Suivi logistique",
    return_tag:"Retour & Remboursement",
    product_tag:"Produit & Stock",
    payment_tag:"Paiement et promotions",
    account_isssues:"Problèmes de compte",
    // contact:
    ticket_time : "UNIQUEMENT pour les problèmes après-vente (Annuler/Modifier la commande/État d'expédition/Retour/Échange, etc.) ",
    online_time :"UNIQUEMENT pour le numéro de pré-vente. Les ventes en ligne n'ont pas accès aux informations de commande.",
    result_for: "résultats pour \"<strong>{result}</strong>\"",
    question:"Vous avez d'autres questions?",
    article_helpful:"Cet article vous a-t-il été utile?",
    related:"Articles liés",
    // ticket
    noTicket:"pas de billet",
    // order
    // ticket add
    textarea_placeholder:"Tapez un message ici...",
    response_time:"Temps de réponse attendu：Dans les 24 h",
    select_tip: 'Veuillez sélectionner votre type de question',
    status_wating: 'Esperando respuestas',
    // question ticket
    submit_tips: "Les raisons ci-dessous sont facultatives. Vous pouvez cliquer sur « X » si vous ne souhaitez en choisir aucun.",
    description_ph : "Veuillez décrire votre problème autant que possible afin que le service client puisse répondre plus rapidement… ",
    upload_image: 'Télécharger une image',
    rate:'Évaluez mon service',
    rate_info: "Nous aimerions savoir ce que vous pensez de notre service client pour nous aider à mieux vous servir.",
    rate_text_placeholder: 'Vous pouvez commenter le service client ici.',
    status_wating_apply:'En attente de candidature',
    facebook_check: 'Si vous cochez cette case, nous vous ferons parvenir le statut de suivi logistique et les informations de commande dans Messenger.',
    return_the_oder : 'Retourner la commande',
    popular_search: 'Recherches populaires : Remboursement, Retour, Expédition',
    vip_service : 'Service VIP',
    copied_successfully:"Copi\u00E9 avec succ\u00E8s", // CM1244
    share_the_code:"Partagez le code", // CM1165

    out_of_stock_policy:"Politique de rupture de stock",
    check_mail_box:"Un lien de vérification a été envoyé à votre adresse e-mail, veuillez vérifier votre boîte aux lettres.",
    success_will_send_latest:"Succès! Nous enverrons notre dernière newsletter à votre nouvelle adresse e-mail.",
    upload_file_size_limit: 'La taille du fichier doit être inférieure à 2 Mo',

    order_processing_shipping_date: 'Nous prenons g\u00e9n\u00e9ralement 3 à 7 jours ouvrables pour traiter votre commande.', // shippingDate
    my_order:"Ma commande",
    my_services:"Mon service",

    set_password_more_discount:"D\u00e9finissez votre mot de passe pour obtenir plus de r\u00e9duction !",   //google
    time_must_current:"La date de naissance doit être inférieure à l'heure actuelle!",

    recently_viewed: "Récemment consultés", 
    you_not_recently_view:"Vous n'avez rien vu.", 
    website_trends:"Tendances {website}",

    verify_check_order: "Vérifier la commande",
    shipping_cost: "frais d'expédition",// shippingCost
    register_and_get: 'Inscrivez-vous sur {siteName} pour suivre votre commande et obtenir plus de réduction !',
    please_input_password_first:'Veuillez d\'abord entrer le mot de passe',
    check_order_msg: 'Maintenant, vous pouvez vérifier votre commande dans votre compte. Veuillez cocher « Ma commande » sous « Moi » pour suivre votre commande.',
    total_count_pages: "{count} pages au total",
    saved: "Épargné",
    use_max_coupon: "N'oubliez pas d'utiliser le coupon de réduction de {coupon} !",
    up_to_saving: "jusqu'à {coupon} d'économies",

    please_select_order_first: 'Veuillez d\'abord sélectionner une commande!',

    exprct_to_save: "S'attendre à économiser",
    max_save: "Épargné",

    step_1_address_content: "Pour voir les articles expédiés vers un autre emplacement, modifiez votre adresse de livraison.",
    next_step: "L'étape suivante",
    step_2_select_content: "Cliquez ici pour sélectionner les articles que vous devez COMMANDER.",
    got_it: "J'ai compris",
    step_2_checkout_content: "Appliquez un code promo et/ou des points {site} à l'étape suivante.",
    shipping_fee: "Frais d'expédition",
    more_products: "Plus de produits",
    change: 'changer',

    bottomLengthNote: "Mesurer de la ceinture à l'ouverture ou à l'ourlet des jambes.",
    inseam: "Entrejambe",
    inseamNote: "Mesurez la longueur entre la couture de l'entrejambe et le bas de la jambe.",
    yourBustNote:"Mesurez la circonférence sur la partie la plus large de votre buste.",
    yourUnderBust: "Votre sous-buste",
    underBustNote: "Mesurez la circonférence sur le dessous de votre buste.",
    swimBustNote: "Mesurez la circonférence sur la partie la plus large de votre poitrine.",
    swimUnderBustNote:"Mesurez la bande sous la poitrine d'un bord à l'autre.",
    swimWaistNote: "Mesurez votre taille à l'endroit le plus fin.",
    swimHipNote: "Mesurez la partie la plus large de vos hanches.",
    switchTo: "Basculer vers",

    underBust: 'sous le buste',
    bustDifference: "Buste - Différence sous le buste",
    expires_soon: "Expire bientôt",
    sign_in_check_coupon:"Connectez-vous pour vérifier plus de coupon",

    footWidth: 'Largeur du pied',
    footLengthNote: 'Mesurez la longueur maximale de votre pied.',
    footWidthNote: 'Mesurez la largeur maximale de votre pied.',

    wholesale: "Vente en gros", // CM0828

    pre_wholesale: "PRE-Gros",
    after_wholesale: "APRÈS-Vente En Gros",

    apply_before_expires: "Appliquez-le avant l'expiration!",
    gift_has_been_cart: "Le cadeau a été ajouté à votre panier!",
    mystery_gifts_giveaway:"cadeaux mystères et gros cadeau",

    got_extra_chance_to_win: "Vous bénéficiez d'une offre VIP exclusive et d'une CHANCE SUPPLÉMENTAIRE de gagner un grand prix !",
    collect_and_draw: 'Collecte et tirage au sort en cours',

    how_rate_website: "Comment évalueriez-vous {website}?",
	please_rate_by_stars: "Veuillez évaluer {website} par étoiles: 1 à 5",
    close: "Fermer",


    purchase_protection: "Paiement protégé",
    efficient_logistics: "Logistique efficace",
    customer_service: "Service client",
    privacy_protection: "Vie privée protégée",
    proceed_to_checkout: "Passer à la caisse",

    enjoy_discount_add_items: "Profitez de réductions uniquement si vous ajoutez des articles de cette liste à votre panier.",
    add_on_items: "Articles complémentaires",

    with_shipping_price: 'avec les frais d\'expédition',
    modify: 'MODIFIER',
	please_sure_address_correct: 'Veuillez vous assurer que l\'adresse que vous indiquez est correcte',
    login_to_receive: "Connectez-vous pour recevoir",

    bottoms: 'BAS',
    coat: "MANTEAU",
    lingerie: 'LINGERIE',
    tops: 'HAUTS',

    addOnItemMsg: 'Remise supplémentaire',
    extraDiscount: 'Réduction Supplémentaire',
    cheapThanAdd: 'Moins cher que l\'ajout',

    can_find_your_size:"Vous ne trouvez pas votre taille?",
    tell_us_size: "Dites-nous votre taille",
    thanks_your_feedback: "Merci pour vos commentaires.",
    submit_successful: "Soumettre avec succès",
    we_found_similar_size: "Nous avons également trouvé des articles similaires pour vous en taille",
    we_optimize_based_feedback:"Nous optimiserons la gamme de tailles en fonction de vos commentaires.",
    similar_item: "Articles similaires",
    not_find_anything_similar_search: "Nous sommes désolés de n'avoir rien trouvé de similaire à ce que vous recherchiez.",

    smaller_than: "Plus petit que",
    larger_than: "Plus grand que",
    spin_to_win_for_mom: 'Tourner pour gagner pour maman!',
    not_your_vibe_there_more: 'Pas adapté? Il y a plus!',
    picks_for_you: 'Sélections pour vous',
    add_to_home_screen:"Ajouter {name} à l'écran d'accueil",

    collect_share: 'Collectez et partagez',
    link_expired: 'Désolé, ce lien a expiré',
    link_valid_for_7_days: 'Il n\'était valable que 7 jours après avoir été partagé.',
    share_coupon_expired: 'Désolé, le coupon partagé a expiré',
    reach_coupon_limit: 'Désolé, vous avez atteint la limite pour les réclamations de coupon',

    your_app_is_outdated: 'Votre version d\'application est obsolète.',
    user_time_limit: 'Désolé, vous avez déjà reçu le coupon. Il ne peut pas être réclamé à nouveau.',

    share: "partager",
    collect_now: "Collecter maintenant",
    gift_card_receive: 'Une carte-cadeau exclusive a été ajoutée à votre pack de coupons',
    get_gift_card_from_share: "Vous recevrez une carte-cadeau exclusive partagée par vos amis",
    limited_discount: 'Réduction limitée',
    enjoy_free_shipping: 'Profitez de la Livraison Gratuite',

    set_the_password: "Définir le mot de passe",
    send_subscribe_message: "Cliquez pour accepter les messages promotionnels de {website} via WhatsApp ou messenger !  Vous pouvez vous désinscrire à tout moment.",
    enter_whatsapp_number: "Veuillez entrer votre numéro WhatsApp",
    send_to_whatsapp: "Envoyer à WhatsApp",
    please_select_location: "Veuillez sélectionner votre lieu de résidence",
    subscribe_success: "S'abonner avec succès!",
    subscribe_filed: "S'abonner sans succès!",
    whatsapp_number: "Numéro WhatsApp",
    receive_offers_message: "Je souhaite recevoir des offres et des informations exclusives par SMS.",

    status:'Statut',
    wish_list_filter_empty: 'Aucun résultat trouvé. Essayez différentes options.',
    wish_list_nothing: 'Vous n\'avez actuellement rien sauvegardé. \'Cœur\' un élément pour commencer votre liste de souhaits.',
    click_to_draw: 'Cliquez pour dessiner',

    wrong_zip_code_us: 'Le code postal doit comporter 5 chiffres ou 5 chiffres suivis d\'un numéro de 4 chiffres, par exemple 20001 ou 20001-0000.',
    wrong_zip_code_uk: 'Le code postal doit contenir de 6 à 8 lettres, chiffres et espaces dans le format M2 5BQ, CW8 3AD, TN37 7HL.',
    wrong_zip_code_br: 'Le code postal doit contenir 8 chiffres séparés par un tiret, selon le format suivant : 12345-123.',
    wrong_zip_code_mx: 'Le code postal doit être un numéro de 5 chiffres, par exemple 12345.',
    wrong_zip_code_de: 'Le code postal doit comporter au moins 5 chiffres. Exemple : 12345.',
    wrong_zip_code_fr: 'Le code postal doit contenir 5 chiffres, par exemple 12345.',
    wrong_zip_code_es: 'Le code postal doit comporter 5 chiffres. Le premier chiffre ne peut pas être 6, 7, 8 ou 9.',
    wrong_zip_code_it: 'Le code postal doit être un numéro de 5 chiffres, par exemple 12345.',
    wrong_zip_code_at: 'Le code postal doit comporter 4 chiffres. Exemple : 1234.',
    wrong_zip_code_be: 'Le code postal doit comporter 4 chiffres. Exemple : 1234.',
    wrong_zip_code_au: 'Le code postal doit être un numéro de 4 chiffres, par exemple 1234.',
    wrong_zip_code_bg: 'Le code postal doit être un numéro de 4 chiffres, par exemple 1234.',
    wrong_zip_code_ca: 'Le format du code postal doit être : lettres + chiffres + lettres + espaces + chiffres + lettres + chiffres, par exemple A1B 2C3.',
    wrong_zip_code_ch: 'Le code postal doit comporter 4 chiffres. Exemple : 1234.',
    wrong_zip_code_cy: 'Le code postal doit être un numéro de 4 chiffres, par exemple 1234.',
    wrong_zip_code_cz: 'Le code postal doit comporter 5 chiffres et 1 espace selon le format 123 45.',
    wrong_zip_code_ee: 'Le code postal doit être un numéro de 5 chiffres, par exemple 12345.',
    wrong_zip_code_fi: 'Le code postal doit être un numéro de 5 chiffres, par exemple 12345.',
    wrong_zip_code_gr: 'Le code postal doit comporter 5 chiffres et 1 espace selon le format 123 45.',
    wrong_zip_code_hr: 'Le code postal doit être un numéro de 5 chiffres, par exemple 12345.',
    wrong_zip_code_ie: 'Le code postal doit contenir 7 lettres, chiffres et espaces selon le format A65 F4E2, T45 D868, T12 FPK5.',
    wrong_zip_code_lt: 'Le code postal doit être un numéro de 5 chiffres, par exemple 12345.',
    wrong_zip_code_lu: 'Le code postal doit être un numéro de 4 chiffres, par exemple 1234.',
    wrong_zip_code_lv: 'Le code postal doit être un numéro de 4 chiffres, par exemple LV-1234',
    wrong_zip_code_nl: 'Le code postal doit comporter 4 chiffres suivis d\'un espace et se terminer par 2 lettres, par exemple 2561 DV.',
    wrong_zip_code_no: 'Le code postal doit être un numéro de 4 chiffres, par exemple 1234.',
    wrong_zip_code_nz: 'Le code postal doit être un numéro de 4 chiffres, par exemple 1234.',
    wrong_zip_code_pl: 'Le code postal doit comporter 5 chiffres et un tiret selon le format 12-345.',
    wrong_zip_code_pt: 'Le code postal doit contenir 7 chiffres séparés par un tiret, selon le format suivant : 1234-123.',
    wrong_zip_code_ro: 'Le code postal doit être un numéro de 6 chiffres, par exemple 123456.',
    wrong_zip_code_se: 'Le code postal doit être un numéro de 5 chiffres, par exemple 12345.',
    wrong_zip_code_dk: 'Le code postal doit être un numéro de 4 chiffres, par exemple 1234.',
    wrong_zip_code_us: 'Code postal : 5 chiffres ou 5 chiffres avec un numéro à 4 chiffres, par ex. 20001 ou 20001-0000',
    wrong_zip_code_uk: 'Code postal : doit contenir 6 à 8 lettres, chiffres et espaces dans le format M2 5BQ, CW8 3AD, TN37 7HL',
    wrong_zip_code_br: 'Code postal : doit contenir 8 chiffres séparés par un trait d\'union, dans le format suivant : 12345-123',
    wrong_zip_code_mx: 'Code postal : doit être un numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_de: 'Code postal : doit comporter au moins 5 chiffres. Exemple : 12345',
    wrong_zip_code_fr: 'Le code postal doit contenir 5 chiffres, par ex. 12345',
    wrong_zip_code_es: 'Code postal : doit comporter 5 chiffres. Le premier chiffre ne peut pas être 6, 7, 8 ou 9',
    wrong_zip_code_it: 'Code postal : doit être un numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_at: 'Le code postal doit être composé de 4 chiffres. Exemple : 1234',
    wrong_zip_code_be: 'Le code postal doit être composé de 4 chiffres. Exemple : 1234',
    wrong_zip_code_au: 'Code postal : doit être un numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_bg: 'Code postal : doit être un numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_ca: 'Format du code postal : lettres + chiffres + lettres + espaces + chiffres + lettres + chiffres, par ex. A1B 2C3',
    wrong_zip_code_ch: 'Le code postal doit être composé de 4 chiffres. Exemple : 1234',
    wrong_zip_code_cy: 'Code postal : doit être un numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_cz: 'Code postal : doit comporter 5 chiffres et 1 espace, dans le format 123 45',
    wrong_zip_code_ee: 'Code postal : doit être un numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_fi: 'Code postal : doit être un numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_gr: 'Code postal : doit comporter 5 chiffres et 1 espace, dans le format 123 45',
    wrong_zip_code_hr: 'Code postal : doit être un numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_ie: 'Code postal : doit contenir 7 lettres, chiffres et espaces dans le format A65 F4E2, T45 D868, T12 FPK5',
    wrong_zip_code_lt: 'Code postal : doit être un numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_lu: 'Code postal : doit être un numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_lv: 'Code postal : doit être un numéro à 4 chiffres, par ex. LV-1234',
    wrong_zip_code_nl: 'Code postal : doit comporter 4 chiffres suivis d\'un espace et de 2 lettres, par ex. 2561 DV',
    wrong_zip_code_no: 'Code postal : doit être un numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_nz: 'Code postal : doit être un numéro à 4 chiffres, par ex. 1234',
    wrong_zip_code_pl: 'Code postal : doit comporter 5 chiffres et "-" dans le format 12-345',
    wrong_zip_code_pt: 'Code postal : doit contenir 7 chiffres séparés par un trait d\'union, dans le format suivant : 1234-123',
    wrong_zip_code_ro: 'Code postal : doit être un numéro à 6 chiffres, par ex. 123456',
    wrong_zip_code_se: 'Code postal : doit être un numéro à 5 chiffres, par ex. 12345',
    wrong_zip_code_da: 'Code postal : doit être un numéro à 4 chiffres, par ex. 1234',

    systemEmailNotice: 'Avis : Veuillez mettre à jour votre adresse e-mail pour recevoir les notifications importantes de notre part. Cliquez pour mettre à jour maintenant.',
    wishListEmptySignIn: 'Liste de souhaits vide. Connectez-vous pour afficher votre liste de souhaits.',
    country_code: "Code pays",
    select_country_code: "Veuillez sélectionner le bon code pays",

    local_policy_pay_tax: "Selon la politique locale, les clients devront payer des impôts sur les opérations de change lorsqu\'ils choisissent ce mode de paiement.",
    returning: "Retour",
    select_to_be_returned: "Veuillez sélectionner le(s) article(s) à retourner",
    cannot_be_returned: "Ce type d'article ne peut pas être retourné",
    are_being_returned: "Le(s) article(s) sont en cours de retour",
    please_select_the_items: "Veuillez sélectionner le(s) article(s)",
    select_reason_all_be_returned: "Veuillez sélectionner la raison pour laquelle tous les articles doivent être retournés",
    please_select_the_reason: "Veuillez sélectionner la raison",
    please_submit_further_details: "Veuillez fournir plus de détails",
    descrive_problem_customer_service_respond: "Décrivez votre problème le plus précisément possible afin que le service client puisse y répondre plus rapidement",
    require_support: "nécessiter de l'aide",

    download: "Télécharger",
    download_all: "Tout télécharger",
    download_products_images: "Télécharger les images du produit",

    drop_shipping: "Expédition directe",
    limit_time_only:"OFFRE VALABLE POUR UNE DURÉE LIMITÉE",
    ruler_min: 'La règle est à son minimum',
    ruler_max: 'La règle est à son maximum',
    swiper_to_add: 'Faites glisser pour ajouter vos informations corporelles',
    save_your_body_mea: 'Nous enregistrerons vos mesures corporelles pour vous assurer un ajustement parfait pour vos futurs achats.',
    mea_perf: 'Préférence',

    safe_payment_options: "Options de paiement sécurisées",

    influencer_outfit: "Tenue d'influenceur",

    sign_in_paypal:"Connectez-vous avec PayPal",
    ends_today: 'se termine aujourd\'hui',

    selected: 'sélectionné',
    addon_reach_limit: "Vous avez atteint la limite des articles complémentaires sélectionnés.Cliquez sur \"Sélectionné\" pour annuler votre choix.",

    phone_number_or_email_address: "Numéro de téléphone ou adresse e-mail",
    reset_password_via_email: "Réinitialiser le mot de passe via l'adresse e-mail",
    reset_password_via_phone: "Réinitialiser le mot de passe via le numéro de téléphone",
    welcome_back: "Bienvenue de retour",
    please_sign_in_account: "Veuillez vous connecter à votre compte.",
    please_create_your_account: "Veuillez créer votre compte.",
    code: 'Code',

    sign_in_with:"Connectez-vous avec {mode}",

    incorrect_email_address:"Adresse e-mail incorrecte",


    privacy_protection: 'Protection de la vie privée',
    security_privacy: 'Sécurité et confidentialité',
    register_success: "Vous vous êtes inscrit avec succès",

    type: 'type',
    spin_to_win: 'Tournez pour Gagner',
    you_run_out_of_chance: "Vous avez épuisé toutes vos chances",
    get_all_in_app: 'obtenir tout dans l\'application',
    app_new_user_gift: 'Cadeau d\'App Nouveau',
    ends_in: 'se termine dans',

    cvv_tip: 'Pour un paiement réussi, veuillez saisir votre numéro de vérification de carte de crédit (CVV). Vous pouvez trouver le numéro CVV à l\'arrière de votre carte de crédit.',

    promotion_details: "Détails de la promotion",
    estimated_price: "Prix estimé",
    buy_now_enjoy: "Achetez maintenant pour profiter des avantages suivants",
    discount_price: "Prix réduit",
    original_price: "Prix d'origine",
    estimated: "Estimé",
    discount_shown_estimated: "Les remises indiquées sont estimées et soumises aux règles des coupons. Le prix final peut varier.",
    promotions: "Promotions",
    get_coupon: "Obtenir un coupon",
    best_coupon: "Meilleur coupon",

    app_exclusive: 'Exclusif',
    unsubscribe_message: 'Réabonnez-vous avec {email} sur {website} et obtenez des coupons.',
    subscribe_and_get: 'Réabonnez-vous et économisez',
}
