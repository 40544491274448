import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { useWebp } from '../../../../../../hooks/common'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchProductDetails,
    saveWishListProduct,
    addToCart,
    selectGift,
    updateAddOnItem,
    removeWishListProduct
} from '../../../../../../../api'
import { getDelPrice, getLowerPrice, getColor, unitPrice, isFlashSales, getLowerPriceWithVariant, getDelPriceWithVariant, getAddOnDelPrice, getAddOnLowerPrice } from '../../../../../../../utils/product'
import {
    setWishList,
    action_fetch_cart,
    action_fetch_cart_count,
    setCartTrigger,
} from '../../../../../../../store/actions/global'
import GeekoEvents from '../../../../../../ads/events'
import { IMAGE_GEEKO_LTD } from '../../../../../../../constants'
import { BigButton } from '../../../../../components/button'
import { Iconfont } from '../../../../../../components/icon/iconfont'
import DisplayTransition from '../../../../../components/transitions/display-transition'
import Mask from '../../../../../components/mask'
import { withRouter } from 'react-router-dom'
import NormalConfirm from '../../../../../../components/alert/confirm'
import Toast from '../../../../../../components/toast'
import { action_fetch_gifts_info_message } from '../../../../../../../store/actions/pages/collection'
import SizeCountrySelector from '../../../../components/size-country-selector'
import { fbAddToCart } from '../../../../../../../api/facebook-ctm-ads'
import { getOriginalSize } from '../../../../../../utils/sensor'

const ImageSwiper = props => {
    useStyles(classes)
    const { product } = props

    const listSwiper = useRef()
    const displaySwiper = useRef()

    const [activeIndex, setActiveIndex] = useState(0)

    const isWebp = useWebp()
    const imagePrefix = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}`

    const emptyImages = [
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
    ]

    const images = product?.pcExtraImageUrls || []
    const listImages = product ? [product?.pcMainImage, ...images].map(image => `${imagePrefix}/medium/${image}`) : emptyImages
    const displayImages = product ? [product?.pcMainImage, ...images].map(image => `${imagePrefix}/large/${image}`) : emptyImages


    const displayHandle = swiper => {
        listSwiper.current?.slideTo(swiper.activeIndex)
        setActiveIndex(swiper.activeIndex)
    }

    const clickHandle = swiper => {
        setActiveIndex(swiper.clickedIndex)
        displaySwiper.current?.slideTo(swiper.clickedIndex)

        if (swiper.clickedIndex === swiper.activeIndex || swiper.clickedIndex === swiper.activeIndex + 1) {
            swiper.slidePrev(300)
        } else {
            swiper.slideToClosest(300)
        }
    }


    return <div className={classes.EditorImageSwiper}>
        <Swiper
            direction="vertical"
            onInit={swiper => listSwiper.current = swiper}
            spaceBetween={7}
            slidesPerView={4}
            className={classes.ListImages}
            onClick={clickHandle}
        >
            {
                listImages?.map((image, index) => <SwiperSlide className={`${classes.EditorListImage} ${activeIndex === index ? classes.Active : ''}`} key={index}>
                    <img src={image} />
                </SwiperSlide>)
            }
        </Swiper>
        <div>
            <Swiper
                spaceBetween={10}
                onInit={swiper => { displaySwiper.current = swiper }}
                onSlideChange={displayHandle}
            >
                {
                    displayImages?.map((image, index) => <SwiperSlide className={classes.EditorDisplayImage} key={index}>
                        <img src={image} />
                    </SwiperSlide>)
                }
            </Swiper>
        </div>
    </div>
}


const ColorSwiper = props => {
    useStyles(classes)
    const { products, selectedProduct, style } = props
    const isWebp = useWebp()
    const imagePrefix = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}`

    const isSameProduct = useCallback((p1, p2) => {
        return p1?.id === p2?.id && p1?.variants?.[0]?.color === p2?.variants?.[0]?.color
    }, [])

    return <div className={classes.Colors}>
        {
            (products || [{}, {}, {}])?.map((product, index) => {
                const imageSuffix = product?.variants?.[0]?.image || product.pcMainImage
                const image = imageSuffix ? `${imagePrefix}/small/${imageSuffix}` : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

                if (style === 1) {
                    return <span style={{
                        backgroundColor: `${getColor(product?.variants?.[0]?.color)}`,
                        backgroundImage: product?.variants?.[0]?.colorImage ? `url(${product?.variants?.[0]?.colorImage})` : undefined
                    }} onClick={() => { props?.onSelect(product) }} className={`${classes.GeneralColor} ${classes.EditorColorPlus} ${isSameProduct(product, selectedProduct) ? classes.Selected : ''}`} key={index}>
                        {
                            product?.hotColor && <span className={classes.HotColor}>
                                <span>Hot</span>
                            </span>
                        }
                    </span>
                }
                return <span onClick={() => { props?.onSelect(product) }} className={`${classes.GeneralColor} ${classes.EditorColor} ${isSameProduct(product, selectedProduct) ? classes.Selected : ''}`} key={index}>
                    <img className={classes.Image} src={image} />

                    {
                        product?.hotColor && <span className={classes.HotColor}>
                            <span>Hot</span>
                        </span>
                    }
                </span>
            })
        }
    </div>
}

const SizeSwiper = props => {
    useStyles(classes)
    const { selectedProduct, variants, selectedVariant } = props
    const global = useSelector(state => state.global)

    const getSizeLabel = size => {
        const countries = selectedProduct?.sizeChart2?.countryAndSizesList?.[0]?.map(c => c.country)
        const sizeDefaultCountry = countries?.find(c => c === global?.sizeDefaultCountry) || selectedProduct?.sizeChart2?.defaultCountry
        const sizes = selectedProduct?.sizeChart2?.countryAndSizesList?.find?.(s => s[0]?.size === size)
        if(sizes?.length > 0){
            return sizes?.find(s => s.country === sizeDefaultCountry)?.size || size
        }
        return size
    }

    const isEnabled = useCallback(v => {
        if (!v) return true
        return v.status === '1' && v.inventory > 0 || (v.domesticDelivery && v.domesticDelivery.enabled && v.domesticDelivery.inventory > 0)
    }, [])

    return <div className={classes.Colors}>
        {
            (variants || [{}, {}, {}])?.map((variant, index) => <span onClick={() => { props?.onSelect(variant, selectedProduct) }} className={`${classes.EditorSize} ${!isEnabled(variant)? classes.SoldOut: ''} ${variant.id === selectedVariant?.id ? classes.Selected : ''}`} key={variant.id}>
                {getSizeLabel(variant?.size)}
            </span>)
        }

    </div>
}

const Quantity = props => {
    const { value, onChange } = props

    const handleClick = (e, type) => {
        const nv = (Number(value) || 1)

        if (type === 'add') {
            onChange(nv + 1)
        } else {
            onChange((nv - 1) < 1 ? 1 : (nv - 1))
        }
    }


    return <span className={classes.Quantity}>
        <Iconfont className={classes.Icon} onClick={e => handleClick(e, 'minus')}>&#xe731;</Iconfont>
        <input type="number" value={value} onChange={e => onChange(e.target.value)} />
        <Iconfont className={classes.Icon} onClick={e => handleClick(e, 'add')}>&#xe733;</Iconfont>
    </span>
}



const ProductEditor = withRouter(props => {
    useStyles(classes)

    const { productId, color, sensors, location, product_type, giftInfo, isLuckyDraw, isAddOn } = props

    const intl = useIntl()
    const isGift = !!giftInfo && !isAddOn || !!isLuckyDraw;

    const dispatch = useDispatch()
    const global = useSelector(state => state.global)
    const { wishlist, cart } = global

    const [productVo, setProductVo] = useState(undefined)
    const [selectedProduct, setSelectedProduct] = useState(undefined)
    const [selectedVariant, setSelectedVariant] = useState(undefined)
    const [quantity, setQuantity] = useState(1)

    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        const params = isAddOn ? {type: 2}: isGift ? {type: 1}: null
        const response = await fetchProductDetails(productId, params)
        if (response.code === 200) {
            setProductVo(response.result)
            const selected = response.result?.products?.find(p => p.id === productId && p.variants?.[0]?.color === color) || response.result?.products?.[0]
            setSelectedProduct(selected)
            if(selected?.saved){
                dispatch(setWishList([...(wishlist || []), productId]))
            }

            //彩妆产品无尺码  默认选择第一个
            // selected?.variants?.length === 1 && !!!selected?.variants?.[0]?.size || response?.result?.style === 1
            if (selected?.variants?.length === 1 || response?.result?.style === 1) {
                setSelectedVariant(selected?.variants?.[0])
            }

        } else {
            alert('Error')
        }
    }, [])

    useEffect(async () => {
        if (productVo) {
            const selectProduct = productVo?.products?.find(p => p.id === productId && p.variants?.[0]?.color === color) || productVo?.products?.[0]
            setSelectedProduct(selectProduct)
            if(selectProduct?.saved){
                dispatch(setWishList([...(wishlist || []), productId]))
            }
        }
    }, [productId, color])

    const isEstimated = selectedProduct?.estimatedPrice && !isAddOn && !isGift

    const estimatedPercent = useMemo(() => {
        let highPrice = getAddOnDelPrice(selectedProduct)
        let estimatedPrice = selectedProduct?.estimatedPrice
        if (estimatedPrice) {
            return Math.round((Math.abs(estimatedPrice?.amount - highPrice?.amount) / highPrice?.amount) * 100)
        } else {
            return null
        }
	}, [selectedProduct])

    const lower = selectedVariant && !isAddOn ?
        getLowerPriceWithVariant(selectedVariant) :
        isAddOn ?
        getAddOnLowerPrice(selectedProduct):
        getLowerPrice(selectedProduct)

    const higher = selectedVariant && !isAddOn ?
            getDelPriceWithVariant(selectedVariant) :
        isAddOn ?
            getAddOnDelPrice(selectedProduct) :
            getDelPrice(selectedProduct)


    let off = 0
    if (higher) {
        off = Math.round((Number(higher.amount) - Number(lower.amount)) * 100 / Number(higher.amount))
    }

    const selectHandle = (product) => {
        setSelectedProduct(product)
        if(product?.saved){
            dispatch(setWishList([...(wishlist || []), product?.id]))
        }
        setSelectedVariant(null)
        if ((product?.variants?.length === 1) || productVo?.style === 1) {
            setSelectedVariant(product?.variants?.[0])
        }
    }


    const sizeHandle = useCallback((v, p) => {
        setSelectedVariant(v)
    }, [])

    const getDescription = useCallback(desc => {
        return desc?.replace(/:[\w|\d|\-|(|)|\.]+;/g, function (value) { return '<span style="color:#999;margin-right: 5px;">' + value + ' </span>' })
    })


    const toggleWishHandle = productId => {
        const hasProduct = wishlist?.some(pId => pId === productId)
        let newWish
        if (hasProduct) {
            newWish = wishlist?.filter(pId => pId !== productId)
            removeWishListProduct(productId)
            dispatch(setWishList(newWish))
        } else {
            newWish = [...(wishlist || []), productId]
            saveWishListProduct(productId).then(data => {
                if (data.code === 300 || data.code === 600) {
                    window.location.href = `/i/login?redirectUrl=${window.location.href}`
                } else if(data.code === 400){
                    alert(data?.result)
                } else {
                    dispatch(setWishList(newWish))
                }
            }).catch(err => {
				if(err?.result){
					alert(err?.result)
				} else {
					console.error(err)
				}
			})
        }

        GeekoEvents.addToWishList({
            productId,
            sensors
        })


    }


    const addToCartHandle = async () => {

        if (selectedVariant) {
            setLoading(true)

            let response;
            if (isGift) {
                response = await selectGift({
                    variantId: selectedVariant.id
                })
            } else if(isAddOn){
                response = await updateAddOnItem({
                    variantId: selectedVariant.id,
                    oldProductId: productId
                })
            } else {
                response = await addToCart({
                    variantId: selectedVariant.id,
                    quantity,
                    unifiedId: selectedVariant.domesticDelivery?.enabled ? selectedVariant.unifiedId : undefined,
                    warehouseId: selectedVariant.domesticDelivery?.enabled ? selectedVariant.domesticDelivery?.warehouseId : undefined,
                    shippedCountryCode: selectedVariant.domesticDelivery?.enabled ? selectedVariant.domesticDelivery?.countryCode : undefined
                })
            }


            setLoading(false)

            if (response.code === 200) {
                props?.onClose()
                dispatch(action_fetch_cart_count())
                await dispatch(action_fetch_cart())
                dispatch(setCartTrigger())

                if (isGift) {
                    if (giftInfo?.difference && !isLuckyDraw) {
                        Toast(giftInfo?.giftWarnMsg, { isDesktop: true });
                    } else {
                        Toast(intl.formatMessage({ id: 'add_successful', defaultMessage: 'Add Successful' }), { isDesktop: true });
                    }
                }
            } else {
                Toast(response.result, { isDesktop: true })
            }

            GeekoEvents.addToCart({
                productId: selectedProduct?.id,
                sku: selectedProduct.parentSku,
                variantId: selectedVariant.id,
                variantSku: selectedVariant.sku,
                quantity,
                amount: lower?.amount,
                currency: lower?.currency,
                product_type: product_type,
                variantSize: getOriginalSize(selectedVariant?.size),
                usdPrice: selectedVariant?.salesPriceWithUSD?.amount,
                name: selectedProduct.name,
                is_success: response?.code === 200,
                isGift: !!isGift,
                sensors
            })

            fbAddToCart(selectedProduct?.id, lower?.amount, lower?.currency)

            if (isGift && !isLuckyDraw) {
                dispatch(action_fetch_gifts_info_message());
            }
        } else {
            if (window.GeekoSensors) {
                window.GeekoSensors.Track('Addtocart', {
                    is_success: false,
                    product_id: selectedProduct?.id,
                    product_qty: quantity,
                })
            }
            Toast(intl.formatMessage({ id: 'please_select_size', defaultMessage: 'Please Select a Size' }), { isDesktop: true });
        }

    }


    const isLiked = wishlist?.some(pId => pId === selectedProduct?.id)


    const isEnabled = useCallback(v => {
        if (!v) return true
        return v.status === '1' && v.inventory > 0 || (v.domesticDelivery && v.domesticDelivery.enabled && v.domesticDelivery.inventory > 0)
    }, [])

    const isFlash = isFlashSales(selectedProduct)
    const isDisabled = !isEnabled(selectedVariant) || (isFlash && selectedProduct?.promotion?.salesQty >= selectedProduct?.promotion?.totalQty)


    const isNew = selectedProduct?.isNew

    const percent = (per) => "-" + (per || 0) + '%'

    return <div className={classes.Editor}>
        <div className={classes.ImageContainer}>
            <ImageSwiper product={selectedProduct} />
        </div>
        <div className={classes.Bd}>
            <div className={classes.EditorName}>
                {
                    selectedProduct?.flag && <span className={`${classes.New} ${classes.PreFall}`}>{selectedProduct.flag}</span>
                }

                {
                    !selectedProduct?.flag && isNew && <span className={classes.New}>
                        <FormattedMessage id="new" defaultMessage="New" />
                    </span>
                }
                <span>{selectedProduct?.name || '-'}</span>
            </div>

            <div className={classes.Sku}>
                SKU: {selectedProduct?.parentSku}
            </div>

            <div className={classes.Prices}>
                {
                    isGift ?
                    <span className={off > 0 ? classes.PromotionLower : classes.Lower}>
                        {
                            selectedProduct ?
                                unitPrice(selectedProduct?.giftPrice || { ...selectedProduct?.price, amount: '0.00' }) :
                                '-'
                        }
                    </span> :
                    <span className={off > 0 ? classes.PromotionLower : classes.Lower}>
                        { unitPrice(lower) || '-' }
                    </span>
                }

                {
                    isEstimated &&
                    <span className={classes.PriceDiscountBox} >
                        <span className={classes.PriceTxt}><FormattedMessage id="estimated" defaultMessage={'Estimated'} /></span>
                        <span className={classes.PriceDiscount}>{unitPrice(selectedProduct?.estimatedPrice)}</span>
                    </span>
                }

                {
                    higher && <del className={classes.Del}>{unitPrice(higher)}</del>
                }
                {
                    off > 0 && <span className={classes.Off}>{`-${off}%`}</span>
                }
            </div>

            {
                selectedVariant?.domesticDelivery?.enabled && <div className={classes.DomesticDelivery}>
                    <img src={selectedVariant?.domesticDelivery?.icon} />
                    <span className={classes.Label} dangerouslySetInnerHTML={{ __html: selectedVariant?.domesticDelivery?.message }} />
                </div>
            }

            <div className={classes.Boxes}>
                {
                    productVo?.products?.length > 1 && <div className={classes.Box}>
                        <div className={classes.Hd}>
                            <span className={classes.Title}>
                                <FormattedMessage id="color" defaultMessage="Color" />
                            </span>
                        </div>
                        <div className={classes.Bd}>
                            <ColorSwiper style={productVo?.style} onSelect={selectHandle} products={productVo?.products} selectedProduct={selectedProduct} />
                            {
                                selectedProduct?.variants?.[0]?.color && <div className={classes.YouSelect}>
                                    <FormattedMessage id="you_selected" values={{
                                        color: selectedProduct?.variants?.[0]?.color
                                    }} defaultMessage="You Selected {color}" />
                                </div>
                            }
                        </div>
                    </div>
                }


                {
                    productVo?.style !== 1 && !(selectedProduct?.variants?.length === 1 && !!!selectedProduct?.variants?.[0]?.size) && <div className={classes.Box}>
                        <div className={classes.Hd}>
                            <span className={classes.Title}>
                                {
                                    selectedProduct?.sizeChart2 ?
                                    <SizeCountrySelector sizeChart2={selectedProduct?.sizeChart2}/>:
                                    <span><FormattedMessage id="size" defaultMessage={'Size'}/>:</span>
                                }
                            </span>
                        </div>
                        <div className={classes.Bd}>
                            <SizeSwiper onSelect={sizeHandle} selectedVariant={selectedVariant} variants={selectedProduct?.variants} selectedProduct={selectedProduct} />
                            <div className={classes.Description} dangerouslySetInnerHTML={{ __html: getDescription(selectedVariant?.description) }} />
                        </div>
                    </div>
                }

                {
                    productVo?.style === 1 && !isGift && <div className={classes.Box}>
                        <div className={classes.Hd}>
                            <span className={classes.Title}>
                                <FormattedMessage id="quantity" defaultMessage="Quantity" />
                            </span>
                        </div>
                        <div className={classes.Bd}>
                            <Quantity value={quantity} onChange={setQuantity} />
                        </div>
                    </div>
                }

                {
                    isFlash && selectedProduct.promotion.maxPurchasePerTimeQty > 0 && <div className={classes.PurchaseLimit}>
                        <FormattedMessage id="purchase_limit" defaultMessage="Purchase limit: {qty} qty" values={{ qty: selectedProduct.promotion.maxPurchasePerTimeQty }} />
                    </div>
                }
            </div>


            <div className={classes.BuyButton}>


                {
                    isDisabled ? <BigButton style={{ width: 245 }} disabled>
                        <FormattedMessage id="sold_out" defaultMessage="Sold Out" />
                    </BigButton> : <BigButton style={{ width: 245 }} onClick={() => {
                        if (isGift && giftInfo?.giftCount) {
                            NormalConfirm(intl.formatMessage({ id: 'free_gift', defaultMessage: 'Free Gifts' }), intl.formatMessage({ id: 'multi_add', defaultMessage: 'Sorry, you can only choose one item as a free gift, if you want add another item, it will replace the free gift you have already added.' }), {
                                onConfirm: addToCartHandle,
                                onClose: () => { props?.onClose() },
                                cancelText: intl.formatMessage({ id: 'cancel', defaultMessage: 'Cancel' }),
                                confirmText: intl.formatMessage({ id: 'confirm', defaultMessage: 'Confirm' }),
                                isDesktop: true
                            })
                        } else {
                            addToCartHandle()
                        }
                    }} loading={loading} disabled={!isEnabled()}>
                        <FormattedMessage id="add_to_bag" defaultMessage="Add to Bag" />
                    </BigButton>
                }

                <Iconfont onClick={() => {
                    toggleWishHandle(selectedProduct?.id)
                }} className={`${classes.CartLike} ${isLiked ? classes.Liked : ''}`}>
                    {
                        isLiked ? <React.Fragment>&#xe775;</React.Fragment> : <React.Fragment>&#xe6a2;</React.Fragment>
                    }
                </Iconfont>
            </div>

            <Iconfont onClick={evt => props?.onClose()} className={classes.Close}>&#xe7c9;</Iconfont>


        </div>

    </div>
})

const WrappedEditor = props => {
    return <React.Fragment>
        <DisplayTransition in={props.showEditor} className={classes.EditorContainer}>
            <div>
                <ProductEditor {...props} />
            </div>
        </DisplayTransition>
        {props.showEditor && <Mask style={{
            zIndex: 50
        }} onClick={props.onClose} />}
    </React.Fragment>
}


export default props => {

    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <WrappedEditor {...props} />,
        document.getElementById('root')
    )
}