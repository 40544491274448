import 
	{ 
		PRODUCT_SET_VO,
		PRODUCT_GET_TRANSLATE_DATA,
		PRODUCT_GET_REVIRE_COMMENTS,
		PRODUCT_GET_REVIRE_COMMENTS_LOADING,
		PRODUCT_GET_REVIRE_COMMENTS_SKIP,
		PRODUCT_GET_REVIRE_COMMENTS_FINISHED,
		PRODUCT_GET_ADD_TO_CART_COUPON_MESSAGE,
		PRODUCT_SET_PRODUCT_IFRAME,
		PRODUCT_SET_PRODUCT_SUMMARY,
		PRODUCT_SET_CATEGORIES,
		PRODUCT_SET_DISCOUNT_MESSAGE,
		SET_PRODUCT_OFTEN_WITH_PRODUCTS,
		SET_PRODUCT_OFTEN_WITH_NO_DATA,
		SET_PRODUCT_AB,
		PRODUCT_ZOOM_IMAGE_POPOVER_PC,
		PRODUCT_SET_BOTTOM_BANNER,
		PRODUCT_SET_PROMOTIONS,
		PRODUCT_SET_SHOP_THE_LOOK,
		PRODUCT_SET_CLEARPAY,
		PRODUCT_SET_TAGLIA,
	} 
from '../../actions/pages/product'

const initialState = {
    productVO: null,
	translateData:null,
	reviewList:[],
	reviewSkip:0,
	reviewLoading:false,
	reviewFinished:false,
	productCouponMsg:null,
	productIframe:null,
	reviewSummary: null,
	categories: null,
	discountMessage: null,
	oftenWithProducts:[],
	oftemWithNoData:false,
	abTest: 'A',
	imagePopover:null,
	bottomBanner: null,
	promotions: null,
	shopTheLookData: null,
	clearpay: null,
	taglia: null,
}

export default (state = initialState, action) => {
    switch(action.type){
		case PRODUCT_SET_VO:
			return {...state, productVO: action.payload}
		case PRODUCT_GET_TRANSLATE_DATA:
			return {...state,translateData:action.payload}
		case PRODUCT_GET_REVIRE_COMMENTS:
			return {...state,reviewList:[...state.reviewList,...action.payload]}
		case PRODUCT_GET_REVIRE_COMMENTS_LOADING:
			return {...state,reviewLoading:action.payload}
		case PRODUCT_GET_REVIRE_COMMENTS_SKIP:
			return {...state,reviewSkip:state.reviewSkip+=action.payload}
		case PRODUCT_GET_REVIRE_COMMENTS_FINISHED:
			return {...state,reviewFinished:action.payload}
		case PRODUCT_GET_ADD_TO_CART_COUPON_MESSAGE:
			return {...state,productCouponMsg:action.payload}
		case PRODUCT_SET_PRODUCT_IFRAME:
			return {...state,productIframe:action.payload}
		case PRODUCT_SET_PRODUCT_SUMMARY:
			return {...state, reviewSummary: action.payload}	
		case PRODUCT_SET_CATEGORIES:
			return {...state, categories: action.payload}	
		case PRODUCT_SET_DISCOUNT_MESSAGE:
			return {...state, discountMessage: action.payload}
		case SET_PRODUCT_OFTEN_WITH_PRODUCTS:
			return {...state,oftenWithProducts:[...state.oftenWithProducts,...action.payload]}
		case SET_PRODUCT_OFTEN_WITH_NO_DATA:
			return {...state,oftemWithNoData: action.payload }
		case SET_PRODUCT_AB:
			return {...state,abTest:action.payload}
		case PRODUCT_ZOOM_IMAGE_POPOVER_PC:
			return {...state,imagePopover:action.payload}
		case PRODUCT_SET_BOTTOM_BANNER:
			return {...state, bottomBanner: action.payload}	
		case PRODUCT_SET_PROMOTIONS:
			return {...state, promotions: action.payload}
		case PRODUCT_SET_SHOP_THE_LOOK:
			return {...state, shopTheLookData: action.payload}
		case PRODUCT_SET_CLEARPAY:
			return {...state, clearpay: action.payload}
		case PRODUCT_SET_TAGLIA:
			return {...state, taglia: action.payload}
		default:
			return state
	}
}